import api from './api';

export default {
  getAllMenuItems() {
    return api.get('/items/all');
  },
  getMenuList(token) {
    return api.get(`/items/` + token);
  },
  addItem(item) {
    return api.post('/items', item);
  },
  editItem(item) {
    return api.put('/items', item);
  },
  //DELETE
  uploadItem(formData) {
    return api.post('/images/upload/item/picture', formData);
  },
  //
  getMenuItemsForAmount() {
    return api.get('/permissions/items');
  },
  putMenuItemsForAmount(obj) {
    return api.put('/items/leftovers', obj);
  },
  //DELETE!!!
  fetchTaxGroup() {
    return api.get('/taxGroups');
  }
};
