export const FETCH_ORDERS_START = (state) => {
  state.isFetchOrdersLoading = true;
  state.isFetchResultError = false;
  state.fetchOrderResult = false;
  state.isFetchOrderErrorMessage = '';
};

export const FETCH_ORDERS_RESULT = (state, data) => {
  state.isFetchOrdersLoading = false;
  state.isFetchResultError = false;
  state.fetchOrderResult = true;
  state.isFetchOrderErrorMessage = '';
  state.fetchOrdersData = data.cafes;
};

export const FETCH_ORDERS_ERROR = (state) => {
  state.isFetchOrdersLoading = false;
  state.isFetchResultError = true;
  state.fetchOrderResult = false;
  state.isFetchOrderErrorMessage = '';
};
//data.cafes === undefined ? [] : data.cafes.orders === undefined ? [] : data.cafes.orders
