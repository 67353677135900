export const addItemCategory = (state) => {
  return state.addItemCategory;
};
export const isAddItemCategoryLoading = (state) => {
  return state.isAddItemCategoryLoading;
};
export const isAddItemCategoryError = (state) => {
  return state.isAddItemCategoryError;
};
export const addItemCategoryErrorMessage = (state) => {
  return state.addItemCategoryErrorMessage;
};
export const addItemCategoryResult = (state) => {
  return state.addItemCategoryResult;
};

export const deleteItemCategory = (state) => {
  return state.deleteItemCategory;
};
export const isDeleteItemCategoryLoading = (state) => {
  return state.isDeleteItemCategoryLoading;
};
export const isDeleteItemCategoryError = (state) => {
  return state.isDeleteItemCategoryError;
};
export const deleteItemCategoryErrorMessage = (state) => {
  return state.deleteItemCategoryErrorMessage;
};
export const deleteItemCategoryResult = (state) => {
  return state.deleteItemCategoryResult;
};

export const categoriesList = (state) => {
  return state.categoriesList;
};
export const isGetCategoriesLoading = (state) => {
  return state.isGetCategoriesLoading;
};
export const isGetCategoriesError = (state) => {
  return state.isGetCategoriesError;
};
export const getCategoriesErrorMessage = (state) => {
  return state.getCategoriesErrorMessage;
};
export const getCategoriesResult = (state) => {
  return state.getCategoriesResult;
};

export const addCategory = (state) => {
  return state.addCategory;
};
export const isAddCategoryLoading = (state) => {
  return state.isAddCategoryLoading;
};
export const isAddCategoryError = (state) => {
  return state.isAddCategoryError;
};
export const addCategoryErrorMessage = (state) => {
  return state.addCategoryErrorMessage;
};
export const addCategoryResult = (state) => {
  return state.addCategoryResult;
};

export const editCategory = (state) => {
  return state.editCategory;
};
export const isEditCategoryLoading = (state) => {
  return state.isEditCategoryLoading;
};
export const isEditCategoryError = (state) => {
  return state.isEditCategoryError;
};
export const editCategoryErrorMessage = (state) => {
  return state.editCategoryErrorMessage;
};
export const editCategoryResult = (state) => {
  return state.editCategoryResult;
};
