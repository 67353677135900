export const cafeList = (state) => {
  return state.cafeList;
};
export const isCafeListLoading = (state) => {
  return state.isCafeListLoading;
};
export const isCafeListError = (state) => {
  return state.isCafeListError;
};
export const cafeListErrorMessage = (state) => {
  return state.cafeListErrorMessage;
};
export const cafeListResult = (state) => {
  return state.cafeListResult;
};

export const currentCafe = (state) => {
  return state.currentCafe;
};
export const isCurrentCafeLoading = (state) => {
  return state.isCurrentCafeLoading;
};
export const isCurrentCafeError = (state) => {
  return state.isCurrentCafeError;
};
export const currentCafeErrorMessage = (state) => {
  return state.currentCafeErrorMessage;
};
export const currentCafeResult = (state) => {
  return state.currentCafeResult;
};

export const loading = (state) => {
  return state.loading;
};
export const error = (state) => {
  return state.error;
};
export const result = (state) => {
  return state.result;
};
export const errorMessage = (state) => {
  return state.errorMessage;
};

// export const isCafeCreateLoading = (state) => {
//   return state.isCafeCreateLoading;
// };
// export const isCafeCreateError = (state) => {
//   return state.isCafeCreateError;
// };
// export const cafeCreateErrorMessage = (state) => {
//   return state.cafeCreateErrorMessage;
// };
// export const cafeCreate = (state) => {
//   return state.cafeCreate;
// };

// export const isCafeChangeLoading = (state) => {
//   return state.isCafeChangeLoading;
// };
// export const isCafeChangeError = (state) => {
//   return state.isCafeChangeError;
// };
// export const cafeChangeErrorMessage = (state) => {
//   return state.cafeChangeErrorMessage;
// };
// export const cafeChange = (state) => {
//   return state.cafeChange;
// };
