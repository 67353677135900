import ReportService from '@/services/ReportService';
import Helper from '@/store/helper';

export const fetchOrdersByDates = ({ commit, dispatch }, { queryParams }) => {
  commit('FETCH_ORDERS_START');
  return ReportService.getOrdersByDates(queryParams)
    .then(({ data }) => {
      commit('SET_ORDERS', data.orders);
    })
    .catch((err) => {
      commit('FETCH_ORDERS_ERROR');
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении логовы: `);
      throw err;
    });
};

export const fetchOrderActions = ({ commit, dispatch }, orderId) => {
  commit('FETCH_ORDER_ACTIONS_START');
  return ReportService.getOrderActions(orderId)
    .then(({ data }) => {
      commit('SET_ORDER_ACTIONS', data);
    })
    .catch((err) => {
      commit('FETCH_ORDER_ACTIONS_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка ORDER: `);
      throw err;
    });
};
export const fetchOrder = ({ commit, dispatch }, orderId) => {
  commit('FETCH_ORDER_START');
  return ReportService.getOrderData(orderId)
    .then(({ data }) => {
      commit('FETCH_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('FETCH_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка ORDER: `);
      throw err;
    });
};
