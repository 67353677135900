export const FETCH_PERMISSION_STATION_LIST = (state) => {
  state.isStationsLoading = true;
  state.isStationsError = false;
  state.stationsListResult = false;
  state.stationsErrorMessage = '';
};

export const SET_PERMISSION_STATIONS = (state, response) => {
  state.isStationsLoading = false;
  state.isStationsError = false;
  state.stationsListResult = response.success;
  state.stationsList = response.stations;
};

export const CREATE_PERMISSION_STATIONS_ERROR = (state, errorMessage) => {
  state.isStationsError = true;
  state.isStationsLoading = false;
  state.stationsListResult = false;
  state.stationsErrorMessage = errorMessage;
};

export const GET_PERMISSION_CAFE_START = (state) => {
  state.isCafeListLoading = true;
  state.isCafeListError = false;
  state.cafeListErrorMessage = '';
  state.cafeListResult = false;
};
export const GET_PERMISSION_CAFE_RESULT = (state, response) => {
  state.isCafeListLoading = false;
  state.isCafeListError = false;
  state.cafeListResult = response.success;
  state.cafeList = response.cafes;
};
export const GET_PERMISSION_CAFE_ERROR = (state, error) => {
  state.isCafeListLoading = false;
  state.isCafeListError = true;
  state.cafeListErrorMessage = error;
};

export const GET_ALL_CAFE_START = (state) => {
  state.isCafeListLoading = true;
  state.isCafeListError = false;
  state.cafeListErrorMessage = '';
  state.cafeListResult = false;
};
export const GET_ALL_CAFE_RESULT = (state, response) => {
  state.isCafeListLoading = false;
  state.isCafeListError = false;
  state.cafeListResult = response.success;
  state.cafeList = response.cafes;
};
export const GET_ALL_CAFE_ERROR = (state, error) => {
  state.isCafeListLoading = false;
  state.isCafeListError = true;
  state.cafeListErrorMessage = error;
};

export const GET_MENU_START = (state) => {
  state.isMenuListLoading = true;
  state.isMenuListError = false;
  state.menuListErrorMessage = '';
  state.menuListResult = false;
};
export const GET_MENU_RESULT = (state, response) => {
  state.isMenuListLoading = false;
  state.isMenuListError = false;
  state.menuListResult = response.success;
  state.cafeInfo = response.cafes[0];
  state.menuList = response.cafes[0].items;
  state.categoriesList = response.cafes[0].categories;
  state.tagList = response.cafes[0].tags;
};

export const GET_MENU_ERROR = (state, error) => {
  state.isMenuListLoading = false;
  state.isMenuListError = true;
  state.menuListResult = false;
  state.menuListErrorMessage = error;
};

export const GET_CATEGORIES_START = (state) => {
  state.isGetCategoriesLoading = false;
  state.isGetCategoriesError = false;
  state.getCategoriesResult = false;
  state.getCategoriesErrorMessage = '';
};
export const GET_CATEGORIES_RESULT = (state, data) => {
  state.isGetCategoriesLoading = false;
  state.isGetCategoriesError = false;
  state.getCategoriesErrorMessage = '';
  state.getCategoriesResult = data.success;
  state.categoriesList = data.cafes[0].categories;
};
export const GET_CATEGORIES_ERROR = (state, error) => {
  state.isGetCategoriesLoading = false;
  state.isGetCategoriesError = true;
  state.getCategoriesErrorMessage = error;
  state.getCategoriesResult = false;
};

export const CREATE_ITEM_START = (state) => {
  state.isCreateItemLoading = true;
  state.isCreateItemError = false;
  state.createItemErrorMessage = '';
  state.createItemResult = false;
};
export const CREATE_ITEM_RESULT = (state, response) => {
  state.isCreateItemLoading = false;
  state.isCreateItemError = false;
  state.createItemResult = response.success;
  state.createItem = response.item;
  state.menuList.push(state.createItem);
};
export const CREATE_ITEM_ERROR = (state, error) => {
  state.isCreateItemLoading = false;
  state.isCreateItemError = true;
  state.createItemErrorMessage = error;
};

export const EDIT_ITEM_START = (state) => {
  state.isEditItemLoading = true;
  state.isEditItemError = false;
  state.editItemErrorMessage = '';
  state.editItemResult = false;
};
export const EDIT_ITEM_RESULT = (state, response) => {
  state.isEditItemLoading = false;
  state.isEditItemError = false;
  if (response.status === 200) {
    state.editItemResult = response.data.success;
    state.editItem = response.data.item;
    state.menuList.find((menuItem) => menuItem.token === state.editItem.token).isActive = state.editItem.isActive;
  }
  if (response.status === 204) {
    state.editItemResult = false;
    state.editItemErrorMessage = 'Ничего не изменилось';
  }
};
export const EDIT_ITEM_ERROR = (state, error) => {
  state.isEditItemLoading = false;
  state.isEditItemError = true;
  state.editItemErrorMessage = error;
};

export const CREATE_CAFE_START = (state) => {
  state.isCreateCafeLoading = true;
  state.isCreateCafeError = false;
  state.createCafeErrorMessage = '';
  state.createCafeResult = false;
};
export const CREATE_CAFE_RESULT = (state, response) => {
  state.isCreateCafeLoading = false;
  state.isCreateCafeError = false;
  state.createCafeResult = response.success;
  state.createCafe = response.item;
  state.cafeList.push(state.createCafe);
};
export const CREATE_CAFE_ERROR = (state, error) => {
  state.isCreateCafeLoading = false;
  state.isCreateCafeError = true;
  state.createCafeErrorMessage = error;
};

export const EDIT_CAFE_START = (state) => {
  state.isEditCafeLoading = true;
  state.isEditCafeError = false;
  state.editCafeErrorMessage = '';
  state.editCafeResult = false;
};
export const EDIT_CAFE_RESULT = (state, response) => {
  state.isEditCafeLoading = false;
  state.isEditCafeError = false;
  if (response.status === 200) {
    state.editCafeResult = response.data.success;
    state.editCafe = response.data.cafe;
    // state.cafeList.find((cafe) => cafe.token === state.editCafe.token).isActive = state.editCafe.isActive;
  }
  if (response.status === 204) {
    state.editCafeResult = false;
    state.editCafeErrorMessage = 'Ничего не изменилось';
  }
};
export const EDIT_CAFE_ERROR = (state, error) => {
  state.isEditCafeLoading = false;
  state.isEditCafeError = true;
  state.editCafeErrorMessage = error;
};
