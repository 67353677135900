export const authResult = (state) => {
  return state.authResult;
};
export const user = (state) => {
  return state.user;
};
export const userPerm = (state) => {
  return state.userPerm;
};
export const isLoggedIn = (state) => {
  return !!state.token;
};
