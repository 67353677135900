import ReferenceService from '@/services/ReferenceService';
import Helper from '@/store/helper';

export const fetchReferenceList = ({ commit, dispatch }) => {
  commit('FETCH_REFERENCE_START');
  return ReferenceService.getReferenceList()
    .then(({ data }) => {
      commit('SET_REFERENCE', data);
    })
    .catch((err) => {
      commit('FETCH_REFERENCE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка REFERENCE: `);
      throw err;
    });
};

export const createOrder = ({ commit, dispatch }, order) => {
  commit('CREATE_REFERENCE_START');
  return ReferenceService.createOrder(order)
    .then(({ data }) => {
      commit('SET_CREATE_REFERENCE_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_REFERENCE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при создании REFERENCE: `);
      throw err;
    });
};

export const confirmAndGetPdf = ({ commit, dispatch }, order) => {
  commit('PDF_START');
  return ReferenceService.confirmAndGetPdf(order)
    .then(({ data }) => {
      commit('SET_PDF_RESULT', data);
    })
    .catch((err) => {
      commit('PDF_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при создании PDF: `);
      throw err;
    });
};
export const takeOrder = ({ commit, dispatch }, orderId) => {
  return ReferenceService.takeOrder(orderId);
};
export const deletePdfData = ({ commit, dispatch }) => {
  commit('DELETE_PDF_DATA');
};
export const addSignToPdf = ({ commit, dispatch }, object) => {
  commit('SIGN_PDF_START');
  return ReferenceService.addSignToPdf(object)
    .then(({ data }) => {
      commit('SIGN_PDF_RESULT', data);
    })
    .catch((err) => {
      commit('SIGN_PDF_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при присоединении PDF: `);
      throw err;
    });
};
export const confirmAndSendPdf = ({ commit, dispatch }, order) => {
  commit('SEND_PDF_START');
  return ReferenceService.confirmAndSendPdf(order)
    .then(({ data }) => {
      commit('SET_SEND_PDF_RESULT', data);
    })
    .catch((err) => {
      commit('SEND_PDF_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при создании PDF: `);
      throw err;
    });
};
export const confirmPayment = ({ commit, dispatch }, token) => {
  commit('CONFIRM_START');
  return ReferenceService.confirmPayment(token)
    .then(({ data }) => {
      commit('CONFIRM_RESULT', data);
    })
    .catch((err) => {
      commit('CONFIRM_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при подтверждении оплаты: `);
      throw err;
    });
};
export const setPaymentToken = ({ commit, dispatch }, token) => {
  commit('SET_PAYMENT_TOKEN', token);
};
export const setOrderTimer = ({ commit, dispatch }, value) => {
  commit('SET_ORDER_TIMER', value);
};

export const clearOrderTimer = ({ commit, dispatch }) => {
  commit('CLEAR_ORDER_TIMER');
};

export const cancelOrder = ({ commit, dispatch }, order) => {
  commit('CANCEL_ORDER_START');
  return ReferenceService.cancelOrder(order)
    .then(({ data }) => {
      commit('CANCEL_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('CANCEL_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};
