import OrderService from '@/services/OrderService';
import Helper from '@/store/helper';

export const createOrder = ({ commit, dispatch }, order) => {
  commit('CREATE_ORDER_START');
  return OrderService.createOrder(order)
    .then(({ data }) => {
      commit('CREATE_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const confirmPayment = ({ commit, dispatch }, token) => {
  commit('CONFIRM_START');
  return OrderService.confirmPayment(token)
    .then(({ data }) => {
      commit('CONFIRM_RESULT', data);
    })
    .catch((err) => {
      commit('CONFIRM_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при подтверждении оплаты: `);
      throw err;
    });
};
export const setPaymentToken = ({ commit, dispatch }, token) => {
  commit('SET_PAYMENT_TOKEN', token);
};
export const setOrderTimer = ({ commit, dispatch }, value) => {
  commit('SET_ORDER_TIMER', value);
};

export const clearOrderTimer = ({ commit }) => {
  commit('CLEAR_ORDER_TIMER');
};

export const fetchOrdersByOperator = ({ commit, dispatch }) => {
  commit('FETCH_ORDERS_START');
  return OrderService.getOrdersList()
    .then(({ data }) => {
      commit('FETCH_ORDERS_RESULT', data);
    })
    .catch((err) => {
      commit('FETCH_ORDERS_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};

export const takeOrder = ({ commit, dispatch }, orderId) => {
  return OrderService.takeOrder(orderId);
};

// --------------------------move in status----------------------------------------
export const acceptOrder = ({ commit, dispatch }, order) => {
  commit('ACCEPT_ORDER_START');
  return OrderService.acceptOrder(order)
    .then(({ data }) => {
      commit('ACCEPT_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('ACCEPT_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};

export const readyOrder = ({ commit, dispatch }, order) => {
  commit('READY_ORDER_START');
  return OrderService.readyOrder(order)
    .then(({ data }) => {
      commit('READY_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('READY_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};

export const setCourierOrder = ({ commit, dispatch }, data) => {
  commit('SET_COURIER_ORDER_START');
  return OrderService.setCourierOrder(data)
    .then(({ data }) => {
      commit('SET_COURIER_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('SET_COURIER_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};

export const awaitOrder = ({ commit, dispatch }, data) => {
  commit('AWAIT_ORDER_START');
  return OrderService.awaitOrder(data)
    .then(({ data }) => {
      commit('AWAIT_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('AWAIT_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};

export const deliveryOrder = ({ commit, dispatch }, data) => {
  commit('DELIVERY_ORDER_START');
  return OrderService.deliveryOrder(data)
    .then(({ data }) => {
      commit('DELIVERY_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('DELIVERY_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};

export const completeOrder = ({ commit, dispatch }, order) => {
  commit('COMPLETE_ORDER_START');
  return OrderService.completeOrder(order)
    .then(({ data }) => {
      commit('COMPLETE_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('COMPLETE_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};

export const cancelOrder = ({ commit, dispatch }, order) => {
  commit('CANCEL_ORDER_START');
  return OrderService.cancelOrder(order)
    .then(({ data }) => {
      commit('CANCEL_ORDER_RESULT', data);
    })
    .catch((err) => {
      commit('CANCEL_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch);
      throw err;
    });
};
