export const isLoading = (state) => {
  return state.isLoading;
};
export const isError = (state) => {
  return state.isError;
};
export const userResult = (state) => {
  return state.userResult;
};
export const userErrorMessage = (state) => {
  return state.userErrorMessage;
};
export const userList = (state) => {
  return state.userList;
};
export const userData = (state) => {
  return state.userData;
};
export const permissionList = (state) => {
  return state.permissionList;
};
export const result = (state) => {
  return state.result;
};

export const newPermission = (state) => {
  return state.newPermission;
};

export const deletedPermission = (state) => {
  return state.deletedPermission;
};

export const newStation = (state) => {
  return state.newStation;
};

export const deletedStation = (state) => {
  return state.deletedStation;
};

export const newCafe = (state) => {
  return state.newCafe;
};

export const deletedCafe = (state) => {
  return state.deletedCafe;
};

export const newDirection = (state) => {
  return state.newDirection;
};

export const deletedDirection = (state) => {
  return state.deletedDirection;
};
