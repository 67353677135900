export const ADD_ITEM_CATEGORY_START = (state) => {
  state.isAddItemCategoryLoading = false;
  state.isAddItemCategoryError = false;
  state.addItemCategoryResult = false;
  state.addItemCategoryErrorMessage = '';
};
export const ADD_ITEM_CATEGORY_RESULT = (state, data) => {
  state.isAddItemCategoryLoading = false;
  state.isAddItemCategoryError = false;
  state.addItemCategoryErrorMessage = '';
  state.addItemCategoryResult = data.success;
  state.addItemCategory = data.link;
};
export const ADD_ITEM_CATEGORY_ERROR = (state, error) => {
  state.isAddItemCategoryLoading = false;
  state.isAddItemCategoryError = true;
  state.addItemCategoryErrorMessage = error;
  state.addItemCategoryResult = false;
};

export const DELETE_ITEM_CATEGORY_START = (state) => {
  state.isDeleteItemCategoryLoading = false;
  state.isDeleteItemCategoryError = false;
  state.deleteItemCategoryResult = false;
  state.deleteItemCategoryErrorMessage = '';
};
export const DELETE_ITEM_CATEGORY_RESULT = (state, data) => {
  state.isDeleteItemCategoryLoading = false;
  state.isDeleteItemCategoryError = false;
  state.deleteItemCategoryErrorMessage = '';
  state.deleteItemCategoryResult = data.success;
  state.deleteItemCategory = data;
};
export const DELETE_ITEM_CATEGORY_ERROR = (state, error) => {
  state.isDeleteItemCategoryLoading = false;
  state.isDeleteItemCategoryError = true;
  state.deleteItemCategoryErrorMessage = error;
  state.deleteItemCategoryResult = false;
};

export const GET_CATEGORIES_START = (state) => {
  state.isGetCategoriesLoading = false;
  state.isGetCategoriesError = false;
  state.getCategoriesResult = false;
  state.getCategoriesErrorMessage = '';
};
export const GET_CATEGORIES_RESULT = (state, data) => {
  state.isGetCategoriesLoading = false;
  state.isGetCategoriesError = false;
  state.getCategoriesErrorMessage = '';
  state.getCategoriesResult = data.success;
  state.categoriesList = data.categories;
};
export const GET_CATEGORIES_ERROR = (state, error) => {
  state.isGetCategoriesLoading = false;
  state.isGetCategoriesError = true;
  state.getCategoriesErrorMessage = error;
  state.getCategoriesResult = false;
};

export const ADD_CATEGORY_START = (state) => {
  state.isAddCategoryLoading = false;
  state.isAddCategoryError = false;
  state.addCategoryResult = false;
  state.addCategoryErrorMessage = '';
};
export const ADD_CATEGORY_RESULT = (state, data) => {
  state.isAddCategoryLoading = false;
  state.isAddCategoryError = false;
  state.addCategoryErrorMessage = '';
  state.addCategoryResult = data.success;
  state.addCategory = data.category;
};
export const ADD_CATEGORY_ERROR = (state, error) => {
  state.isAddCategoryLoading = false;
  state.isAddCategoryError = true;
  state.addCategoryErrorMessage = error;
  state.addCategoryResult = false;
};

export const EDIT_CATEGORY_START = (state) => {
  state.isEditCategoryLoading = false;
  state.isEditCategoryError = false;
  state.editCategoryResult = false;
  state.editCategoryErrorMessage = '';
};
export const EDIT_CATEGORY_RESULT = (state, data) => {
  state.isEditCategoryLoading = false;
  state.isEditCategoryError = false;
  state.editCategoryErrorMessage = '';
  state.editCategoryResult = data.success;
  state.editCategory = data;
};
export const EDIT_CATEGORY_ERROR = (state, error) => {
  state.isEditCategoryLoading = false;
  state.isEditCategoryError = true;
  state.editCategoryErrorMessage = error;
  state.editCategoryResult = false;
};
