export const UPLOAD_PICTURE_START = (state) => {
  state.isPictureLoading = false;
  state.isPictureError = false;
  state.pictureResult = false;
  state.pictureErrorMessage = '';
};
export const UPLOAD_PICTURE_RESULT = (state, data) => {
  state.isPictureLoading = false;
  state.isPictureError = false;
  state.pictureErrorMessage = '';
  state.pictureResult = data.success;
  state.picture = data.file;
};
export const UPLOAD_PICTURE_ERROR = (state, error) => {
  state.isPictureLoading = false;
  state.isPictureError = true;
  state.pictureErrorMessage = error;
  state.pictureResult = false;
};

export const DESTROY_PICURE = (state) => {
  state.picture = null;
};
