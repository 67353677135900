export default {
  cafeList: [],
  isCafeListLoading: false,
  isCafeListError: false,
  cafeListResult: false,
  cafeListErrorMessage: '',

  currentCafe: {},
  isCurrentCafeLoading: false,
  isCurrentCafeError: false,
  currentCafeResult: false,
  currentCafeErrorMessage: '',

  cafeCreate: [],
  isCafeCreateLoading: false,
  isCafeCreateError: false,
  cafeCreateErrorMessage: '',

  cafeChange: [],
  isCafeChangeLoading: false,
  isCafeChangeError: false,
  cafeChangeErrorMessage: '',

  loading: false,
  error: false,
  result: false,
  errorMessage: ''
};
