export default {
  isLoading: false,
  isError: false,
  userResult: false,
  userErrorMessage: false,
  userList: [],
  userData: null,
  permissionList: [],
  result: false,
  newPermission: null,
  deletedPermission: null,
  newStation: null,
  deletedStation: null,
  newCafe: null,
  deletedCafe: null,
  newDirection: null,
  deletedDirection: null
};
