export const isOrderActionsLoading = (state) => {
  return state.isOrderActionsLoading;
};
export const isOrderActionsError = (state) => {
  return state.isOrderActionsError;
};
export const orderActionsErrorMessage = (state) => {
  return state.orderActionsErrorMessage;
};
export const orderActions = (state) => {
  return state.orderActions;
};

export const orders = (state) => {
  return state.orders;
};
export const isOrdersLoading = (state) => {
  return state.isOrdersLoading;
};
export const isOrdersError = (state) => {
  return state.isOrdersError;
};

export const isOrderLoading = (state) => {
  return state.isOrderLoading;
};
export const isOrderError = (state) => {
  return state.isOrderError;
};
export const orderErrorMessage = (state) => {
  return state.orderErrorMessage;
};
export const orderById = (state) => {
  return state.orderById;
};
