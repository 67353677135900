let nextId = 1;

export const PUSH_NOTIFICATION = (state, notification) => {
  state.notifications.push({
    ...notification,
    id: nextId++
  });
};

export const REMOVE_NOTIFICATION = (state, notification) => {
  state.notifications = state.notifications.filter((item) => {
    return item.id != notification.id;
  });
};

export const SHOW_MESSAGE = (state, payload) => {
  state.message = payload.message;
  state.timer = payload.timer;
  state.color = payload.color;
  state.icon = payload.icon;
};
