export const picture = (state) => {
  return state.picture;
};
export const isPictureLoading = (state) => {
  return state.isPictureLoading;
};
export const isPictureError = (state) => {
  return state.isPictureError;
};
export const pictureResult = (state) => {
  return state.pictureResult;
};
export const pictureErrorMessage = (state) => {
  return state.pictureErrorMessage;
};
