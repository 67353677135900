import api from './api';
export default {
  uploadItem(formData) {
    return api.post('/images/upload/item/picture', formData);
  },
  uploadCafe(formData) {
    return api.post('/images/upload/cafe/picture', formData);
  },
  uploadCategory(formData) {
    return api.post('/images/upload/category/pikto', formData);
  },
  uploadTag(formData) {
    return api.post('/images/upload/category/pikto', formData);
  }
};
