export const GET_MENU_START = (state) => {
  state.isMenuListLoading = true;
  state.isMenuListError = false;
  state.menuListErrorMessage = '';
  state.menuListResult = false;
};
export const GET_MENU_RESULT = (state, response) => {
  state.isMenuListLoading = false;
  state.isMenuListError = false;
  state.menuListResult = response.success;
  response.items.forEach((item) => (item.count = 0));
  state.menuList = response.items;
  state.categoriesList = response.categories;
  response.categories.forEach((category) => (category.products = []));
  // const savedCartProducts = JSON.parse(localStorage.getItem('cartProducts'));
  // const savedCafeToken = localStorage.getItem('cafeToken');
  // if (savedCartProducts && savedCafeToken) {
  let hashTable = {};
  for (let obj2 of state.productsInCart) {
    hashTable[obj2.token] = obj2.count;
  }
  for (let obj1 of state.menuList) {
    let newCount = hashTable[obj1.token];
    if (newCount !== undefined) {
      obj1.count = newCount;
    }
  }
};
export const GET_MENU_ERROR = (state, error) => {
  state.isMenuListLoading = false;
  state.isMenuListError = true;
  state.menuListResult = false;
  state.menuListErrorMessage = error;
};

export const CREATE_ITEM_START = (state) => {
  state.isCreateItemLoading = true;
  state.isCreateItemError = false;
  state.createItemErrorMessage = '';
  state.createItemResult = false;
};
export const CREATE_ITEM_RESULT = (state, response) => {
  state.isCreateItemLoading = false;
  state.isCreateItemError = false;
  state.createItemResult = response.success;
  state.createItem = response.item;
};
export const CREATE_ITEM_ERROR = (state, error) => {
  state.isCreateItemLoading = false;
  state.isCreateItemError = true;
  state.createItemErrorMessage = error;
};

export const EDIT_ITEM_START = (state) => {
  state.isEditItemLoading = true;
  state.isEditItemError = false;
  state.editItemErrorMessage = '';
  state.editItemResult = false;
};
export const EDIT_ITEM_RESULT = (state, response) => {
  state.isEditItemLoading = false;
  state.isEditItemError = false;
  if (response.status === 200) {
    state.editItemResult = response.success;
    state.editItem = response.item;
  }
  if (response.status === 204) {
    state.editItemResult = false;
    state.editItemErrorMessage = 'Ничего не изменилось';
  }
};
export const EDIT_ITEM_ERROR = (state, error) => {
  state.isEditItemLoading = false;
  state.isEditItemError = true;
  state.editItemErrorMessage = error;
};

export const GET_MENU_ITEMS_FOR_AMOUNT_START = (state) => {
  state.isMenuItemsForAmountLoading = true;
  state.isMenuItemsForAmountError = false;
  state.menuItemsForAmountResult = false;
  state.menuItemsForAmountErrorMessage = '';
};
export const GET_MENU_ITEMS_FOR_AMOUNT_RESULT = (state, data) => {
  state.isMenuItemsForAmountLoading = false;
  state.isMenuItemsForAmountError = false;
  state.menuItemsForAmountErrorMessage = '';
  state.menuItemsForAmountResult = data.success;
  state.menuItemsForAmount = data.cafes;
};
export const GET_MENU_ITEMS_FOR_AMOUNT_ERROR = (state, error) => {
  state.isMenuItemsForAmountLoading = false;
  state.isMenuItemsForAmountError = true;
  state.menuItemsForAmountErrorMessage = error;
  state.menuItemsForAmountResult = false;
};

export const PUT_MENU_ITEMS_FOR_AMOUNT_START = (state) => {
  state.isMenuItemsForPutAmountLoading = true;
  state.isMenuItemsForPutAmountError = false;
  state.menuItemsForPutAmountResult = false;
  state.menuItemsForPutAmountErrorMessage = '';
};
export const PUT_MENU_ITEMS_FOR_AMOUNT_RESULT = (state, data) => {
  state.isMenuItemsForPutAmountLoading = false;
  state.isMenuItemsForPutAmountError = false;
  state.menuItemsForPutAmountErrorMessage = '';
  state.menuItemsForPutAmountResult = data.success;
  state.menuItemsForPutAmount = data.orders;
};
export const PUT_MENU_ITEMS_FOR_AMOUNT_ERROR = (state, error) => {
  state.isMenuItemsForPutAmountLoading = false;
  state.isMenuItemsForPutAmountError = true;
  state.menuItemsForPutAmountErrorMessage = error;
  state.menuItemsForPutAmountResult = false;
};

export const GET_TAX_GROUP_START = (state) => {
  state.isTaxGroupsLoading = true;
  state.isTaxGroupsError = false;
  state.taxGroupsResult = false;
  state.taxGroupsErrorMessage = '';
};
export const GET_TAX_GROUP_RESULT = (state, data) => {
  state.isTaxGroupsLoading = false;
  state.isTaxGroupsError = false;
  state.taxGroupsErrorMessage = '';
  state.taxGroupsResult = data.success;
  state.taxGroups = data.taxGroups;
};
export const GET_TAX_GROUP_ERROR = (state, error) => {
  state.isTaxGroupsLoading = false;
  state.isTaxGroupsError = true;
  state.taxGroupsErrorMessage = error;
  state.taxGroupsResult = false;
};

// FOR CART
export const SET_CAFE_TOKEN = (state, cafeToken) => {
  state.cafeToken = cafeToken;
  localStorage.setItem('cafeToken', cafeToken);
};

export const SET_CART_PRODUCTS = (state, productsInCart) => {
  state.productsInCart = productsInCart;
  let hashTable = {};
  for (let obj2 of productsInCart) {
    hashTable[obj2.token] = obj2.count;
  }
  for (let obj1 of state.menuList) {
    let newCount = hashTable[obj1.token];
    if (newCount !== undefined) {
      obj1.count = newCount;
    }
  }
};

export const CLEAR_CART = (state) => {
  state.productsInCart = [];
  state.menuList.forEach((item) => (item.cout = 0));
  localStorage.removeItem('cartProducts');
};

export const ADD_TO_CART = (state, payload) => {
  payload.count += 1;
  let currentProduct = state.productsInCart.find((product) => product.token === payload.token);
  if (currentProduct) {
    currentProduct.count = payload.count;
  } else state.productsInCart.push(payload);
  localStorage.setItem('cartProducts', JSON.stringify(state.productsInCart));
};

export const REMOVE_FROM_CART = (state, payload) => {
  payload.count -= 1;
  let currentProduct = state.productsInCart.find((product) => product.token === payload.token);
  currentProduct.count = payload.count;
  state.productsInCart = state.productsInCart.filter((product) => product.count !== 0);
  if (state.productsInCart.length > 0) {
    localStorage.setItem('cartProducts', JSON.stringify(state.productsInCart));
  } else {
    localStorage.removeItem('cartProducts');
    localStorage.removeItem('cafeToken');
  }
};

export const INCREMENT = (state, token) => {
  state.menuList.find((item) => item.token === token).count += 1;
};
export const DECREMENT = (state, token) => {
  state.menuList.find((item) => item.token === token).count -= 1;
};
