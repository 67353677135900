export const LOGIN_CLIENT_START = (state) => {
  state.isLoginClientLoading = true;
  state.isLoginClientError = false;
  state.loginClientErrorMessage = '';
  state.loginClientResult = false;
};
export const LOGIN_CLIENT_SET = (state, data) => {
  localStorage.setItem('client', JSON.stringify(data.client));
  state.isLoginClientLoading = false;
  state.isLoginClientError = false;
  state.loginClientResult = data.success;
  state.loginClientData = data.client;
  state.loginClientErrorMessage = '';
};
export const LOGIN_CLIENT_ERROR = (state, err) => {
  state.isLoginClientLoading = false;
  state.isLoginClientError = true;
  state.loginClientErrorMessage = err;
};
export const FILL_CLIENT_INFO = (state) => {
  if (state.loginClientData === undefined || state.loginClientData === null || state.loginClientData.length <= 0) {
    const localStorageData = JSON.parse(localStorage.getItem('client'));
    if (localStorageData !== null && localStorageData !== undefined) {
      state.loginClientData = localStorageData;
    }
  }
};

export const LOGOUT_CLIENT = (state) => {
  localStorage.removeItem('client');
  state.loginClientData = null;
};

export const CURRENT_ORDER_START = (state) => {
  state.isCurrentOrderLoading = true;
  state.isCurrentOrderError = false;
  state.currentOrderErrorMessage = '';
  state.currentOrderResult = false;
};
export const CURRENT_ORDER_SET = (state, data) => {
  state.isCurrentOrderLoading = false;
  state.isCurrentOrderError = false;
  state.currentOrderResult = data.success;
  state.currentOrderData = data.order;
  state.currentOrderErrorMessage = '';
};
export const CURRENT_ORDER_ERROR = (state, err) => {
  state.isCurrentOrderLoading = false;
  state.isCurrentOrderError = true;
  state.currentOrderErrorMessage = err;
};

export const ALL_ORDERS_START = (state) => {
  state.isAllOrdersLoading = true;
  state.isAllOrdersError = false;
  state.allOrdersErrorMessage = '';
  state.allOrdersResult = false;
};
export const ALL_ORDERS_SET = (state, data) => {
  state.isAllOrdersLoading = false;
  state.isAllOrdersError = false;
  state.allOrdersResult = data.success;
  state.allOrdersData = data.orders;
  state.allOrdersErrorMessage = '';
};
export const ALL_ORDERS_ERROR = (state, err) => {
  state.isAllOrdersLoading = false;
  state.isAllOrdersError = true;
  state.allOrdersErrorMessage = err;
};
export const SET_CLIENT_ORDER_TIMER = (state, value) => {
  state.clientOrderTimer = value;
};

export const CLEAR_CLIENT_ORDER_TIMER = (state) => {
  state.clientOrderTimer = null;
};
