import api from './api';

export default {
  getAllClients() {
    return api.get('/clients');
  },
  clientLogin(info) {
    return api.post('/clients/login', info);
  },
  clientOrder(info) {
    return api.post('/clients/order', info);
  },
  clientOrders(info) {
    return api.post('/clients/last', info);
  }
};
