export const DIRECTION_LIST_START = (state) => {
  state.isDirectionListLoading = true;
  state.isDirectionListError = false;
  state.directionListResult = false;
  state.directionListErrorMessage = '';
};
export const DIRECTION_LIST_RESULT = (state, response) => {
  state.isDirectionListLoading = false;
  state.isDirectionListError = false;
  state.directionListResult = response.success;
  state.directionList = response.directions;
};
export const DIRECTION_LIST_ERROR = (state, errorMessage) => {
  state.isDirectionListLoading = false;
  state.isDirectionListError = true;
  state.directionListResult = false;
  state.directionListErrorMessage = errorMessage;
};
