export const stationsList = (state) => {
  return state.stationsList;
};
export const isStationsLoading = (state) => {
  return state.isStationsLoading;
};
export const isStationsError = (state) => {
  return state.isStationsError;
};
export const stationsErrorMessage = (state) => {
  return state.stationsErrorMessage;
};
export const isAddStationLoading = (state) => {
  return state.isAddStationLoading;
};
export const isAddStationError = (state) => {
  return state.isAddStationError;
};
export const isAddStaionResult = (state) => {
  return state.isAddStaionResult;
};
export const addStationMessage = (state) => {
  return state.addStationMessage;
};

export const isDirectionListLoading = (state) => {
  return state.isDirectionListLoading;
};
export const isDirectionListError = (state) => {
  return state.isDirectionListError;
};
export const directionListResult = (state) => {
  return state.directionListResult;
};
export const directionListErrorMessage = (state) => {
  return state.directionListErrorMessage;
};
export const directionList = (state) => {
  return state.directionList;
};

export const resultData = (state) => {
  return state.resultData;
};
export const isLoading = (state) => {
  return state.isLoading;
};
export const isError = (state) => {
  return state.isError;
};
export const result = (state) => {
  return state.result;
};
export const errorMessage = (state) => {
  return state.errorMessage;
};
