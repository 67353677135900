export const GET_TAG_START = (state) => {
  state.isTagsListLoading = true;
  state.isTagsListError = false;
  state.tagsListErrorMessage = '';
  state.tagsListResult = false;
};
export const GET_TAG_RESULT = (state, response) => {
  state.isTagsListLoading = false;
  state.isTagsListError = false;
  state.tagsListResult = response.success;
  state.tagsList = response.tags;
};

export const GET_TAG_ERROR = (state, error) => {
  state.isTagsListLoading = false;
  state.isTagsListError = true;
  state.tagsListErrorMessage = error;
};

export const CREATE_TAG_START = (state) => {
  state.isCreateTagLoading = true;
  state.isCreateTagError = false;
  state.createTagErrorMessage = '';
  state.createTagResult = false;
};

export const CREATE_TAG_RESULT = (state, response) => {
  state.isCreateTagLoading = false;
  state.isCreateTagError = false;
  state.createTagResult = response.success;
  state.tagsList.push(response.tag);
};

export const CREATE_TAG_ERROR = (state, error) => {
  state.isCreateTagLoading = false;
  state.isCreateTagError = true;
  state.createTagErrorMessage = error;
};

export const EDIT_TAG_START = (state) => {
  state.isEditTagLoading = true;
  state.isEditTagError = false;
  state.editTagErrorMessage = '';
  state.editTagResult = false;
};

export const EDIT_TAG_RESULT = (state, response) => {
  state.isEditTagLoading = false;
  state.isEditTagError = false;
  state.editTagResult = response.success;
  state.editTag = response.tags;
};

export const EDIT_TAG_ERROR = (state, error) => {
  state.isEditTagLoading = false;
  state.isEditTagError = true;
  state.editTagErrorMessage = error;
};

export const ADD_TAG_START = (state) => {
  state.isAddCafeTagLoading = true;
  state.isAddCafeTagError = false;
  state.addCafeTagErrorMessage = '';
  state.addCafeTagResult = false;
};

export const ADD_TAG_RESULT = (state, response) => {
  state.isAddCafeTagLoading = false;
  state.isAddCafeTagError = false;
  state.addCafeTagResult = response.success;
  // state.tagsList.find((tag) => tag.token !== response.tag.token);
};

export const ADD_TAG_ERROR = (state, error) => {
  state.isAddCafeTagLoading = false;
  state.isAddCafeTagError = true;
  state.addCafeTagErrorMessage = error;
};

export const DELETE_TAG_START = (state) => {
  state.isAddCafeTagLoading = true;
  state.isAddCafeTagError = false;
  state.addCafeTagErrorMessage = '';
  state.addCafeTagResult = false;
};

export const DELETE_TAG_RESULT = (state, response) => {
  state.isDeleteCafeTagLoading = false;
  state.isDeleteCafeTagError = false;
  state.deleteCafeTagResult = response.success;
  // state.tagsList.find((tag) => tag.token !== response.tag.token);
};

export const DELETE_TAG_ERROR = (state, error) => {
  state.isDeleteCafeTagLoading = false;
  state.isDeleteCafeTagError = true;
  state.deleteCafeTagErrorMessage = error;
};
