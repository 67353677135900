import PictureService from '@/services/PictureService';
import Helper from '@/store/helper';

export const uploadProductPicture = ({ commit, dispatch }, formData) => {
  commit('UPLOAD_PICTURE_START');
  return PictureService.uploadItem(formData)
    .then(({ data }) => {
      commit('UPLOAD_PICTURE_RESULT', data);
    })
    .catch((err) => {
      commit('UPLOAD_PICTURE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
export const uploadCafePicture = ({ commit, dispatch }, formData) => {
  commit('UPLOAD_PICTURE_START');
  return PictureService.uploadCafe(formData)
    .then(({ data }) => {
      commit('UPLOAD_PICTURE_RESULT', data);
    })
    .catch((err) => {
      commit('UPLOAD_PICTURE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
export const uploadCategoryPicture = ({ commit, dispatch }, formData) => {
  commit('UPLOAD_PICTURE_START');
  return PictureService.uploadCategory(formData)
    .then(({ data }) => {
      commit('UPLOAD_PICTURE_RESULT', data);
    })
    .catch((err) => {
      commit('UPLOAD_PICTURE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
export const uploadTagPicture = ({ commit, dispatch }, formData) => {
  commit('UPLOAD_PICTURE_START');
  return PictureService.uploadTag(formData)
    .then(({ data }) => {
      commit('UPLOAD_PICTURE_RESULT', data);
    })
    .catch((err) => {
      commit('UPLOAD_PICTURE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
export const destroyProductPicture = ({ commit, dispatch }) => {
  commit('DESTROY_PICURE');
};
