export default {
  isQrLoading: false,
  isQrError: false,
  qrErrorMessage: false,
  qrResult: false,
  qrData: '',

  isQrListLoading: false,
  isQrListError: false,
  qrListErrorMessage: false,
  qrListResult: false,
  qrListData: [],
  dataForCreateQr: [],

  isCreateQrLoading: false,
  isCreateQrError: false,
  createQrErrorMessage: false,
  createQrResult: '',
  createQrData: [],

  isDeleteQrLoading: false,
  isDeleteQrError: false,
  deleteQrErrorMessage: false,
  deleteQrResult: '',
  deleteQrData: []
};
