export const menuList = (state) => {
  return state.menuList;
};
export const isMenuListLoading = (state) => {
  return state.isMenuListLoading;
};
export const isMenuListError = (state) => {
  return state.isMenuListError;
};
export const menuListErrorMessage = (state) => {
  return state.menuListErrorMessage;
};
export const menuListResult = (state) => {
  return state.menuListResult;
};

export const categoriesList = (state) => {
  return state.categoriesList;
};

export const createItem = (state) => {
  return state.createItem;
};
export const isCreateItemLoading = (state) => {
  return state.isCreateItemLoading;
};
export const isCreateItemError = (state) => {
  return state.isCreateItemError;
};
export const createItemErrorMessage = (state) => {
  return state.createItemErrorMessage;
};
export const createItemResult = (state) => {
  return state.createItemResult;
};

export const editItem = (state) => {
  return state.editItem;
};
export const isEditItemLoading = (state) => {
  return state.isEditItemLoading;
};
export const isEditItemError = (state) => {
  return state.isEditItemError;
};
export const editItemErrorMessage = (state) => {
  return state.editItemErrorMessage;
};
export const editItemResult = (state) => {
  return state.editItemResult;
};

export const isMenuItemsForAmountLoading = (state) => {
  return state.isMenuItemsForAmountLoading;
};
export const isMenuItemsForAmountError = (state) => {
  return state.isMenuItemsForAmountError;
};
export const menuItemsForAmountResult = (state) => {
  return state.menuItemsForAmountResult;
};
export const menuItemsForAmountErrorMessage = (state) => {
  return state.menuItemsForAmountErrorMessage;
};
export const menuItemsForAmount = (state) => {
  return state.menuItemsForAmount;
};

export const isMenuItemsForPutAmountLoading = (state) => {
  return state.isMenuItemsForPutAmountLoading;
};
export const isMenuItemsForPutAmountError = (state) => {
  return state.isMenuItemsForPutAmountError;
};
export const menuItemsForPutAmountResult = (state) => {
  return state.menuItemsForPutAmountResult;
};
export const menuItemsForPutAmountErrorMessage = (state) => {
  return state.menuItemsForPutAmountErrorMessage;
};
export const menuItemsForPutAmount = (state) => {
  return state.menuItemsForPutAmount;
};

export const isTaxGroupsLoading = (state) => {
  return state.isTaxGroupsLoading;
};
export const isTaxGroupsError = (state) => {
  return state.isTaxGroupsError;
};
export const taxGroupsResult = (state) => {
  return state.taxGroupsResult;
};
export const taxGroupsErrorMessage = (state) => {
  return state.taxGroupsErrorMessage;
};
export const taxGroups = (state) => {
  return state.taxGroups;
};

export const increment = (state) => {
  return state.count;
};

export const decrement = (state) => {
  return state.count;
};
