import api from './api';

export default {
  getTakeoutsList() {
    return api.get('/takeouts');
  },
  createTakeout(data) {
    return api.post('/takeouts', data);
  },
  editTakeout(data) {
    return api.put('/takeouts', data);
  },
  createCafeTakeout(data) {
    return api.post('/links/cafeTakeout/create', data);
  },
  deleteCafeTakeout(data) {
    return api.post('/links/cafeTakeout/delete', data);
  },
  getPermissionTakeoutsList() {
    return api.get('/permissions/takeouts');
  }
};
