import api from './api';

export default {
  get() {
    return api.get('/users');
  },
  post(user) {
    return api.post('/users', user);
  },
  put(user) {
    return api.put('/users', user);
  },
  getById(id) {
    return api.get('/users/' + id);
  },
  getUserBySuperAdmin() {
    return api.get('/permissions/users');
  },
  getPermission() {
    return api.get('/permissions');
  },
  getUserPermission() {
    return api.get('/links/userPermission');
  },
  createUserPermission(user) {
    return api.post('/links/userPermission/create', user);
  },
  updateUserPermission(user) {
    return api.put('/links/userPermission/update', user);
  },
  deleteUserPermission(user) {
    return api.post('/links/userPermission/delete', user);
  },
  createUserDirection(user) {
    return api.post('/links/userDirection/create', user);
  },
  deleteUserDirection(user) {
    return api.post('/links/userDirection/delete', user);
  },
  createUserStation(user) {
    return api.post('/links/userStation/create', user);
  },
  deleteUserStation(user) {
    return api.post('/links/userStation/delete', user);
  },
  createUserCafe(user) {
    return api.post('/links/userCafe/create', user);
  },
  deleteUserCafe(user) {
    return api.post('/links/userCafe/delete', user);
  },
  createUserCourierCo(user) {
    return api.post('/links/userCourierCo/create', user);
  },
  deleteUserCourierCo(user) {
    return api.post('/links/userCourierCo/delete', user);
  },
  getUserByDirection() {
    return api.get('/permissions/users/direction');
  },
  getUserByStation() {
    return api.get('/permissions/users/station');
  },
  getUserByCafe() {
    return api.get('/permissions/users/cafe');
  }
};
