import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/es5/locale/ru';
import VkLogo from '../components/logos/VkLogo';
import TelegramLogo from '../components/logos/TelegramLogo';
import RzdLogo from '../components/logos/RzdLogo';
import DrzhLogo from '../components/logos/DrzhLogo';
import AccountLogo from '../components/logos/AccountLogo';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  icons: {
    values: {
      VkLogo: {
        component: VkLogo
      },
      TelegramLogo: {
        component: TelegramLogo
      },
      RzdLogo: {
        component: RzdLogo
      },
      DrzhLogo: {
        component: DrzhLogo
      },
      AccountLogo: {
        component: AccountLogo
      }
    }
  }
});
