import api from './api';

export default {
  //client
  getCafeTags() {
    return api.get('/tags');
  },
  //business
  addCafeTags(tag) {
    return api.post('/tags', tag);
  },
  editCafeTags(tag) {
    return api.put('/tags', tag);
  },
  deleteTag(token) {
    return api.get('/tags/' + token);
  },
  addCafeTag(tag) {
    return api.post('/links/cafeTag/create', tag);
  },
  deleteCafeTag(tag) {
    return api.post('/links/cafeTag/delete', tag);
  }
};
