export const stationsList = (state) => {
  return state.stationsList;
};
export const isStationsLoading = (state) => {
  return state.isStationsLoading;
};
export const isStationsError = (state) => {
  return state.isStationsError;
};
export const stationsErrorMessage = (state) => {
  return state.stationsErrorMessage;
};
export const stationsListResult = (state) => {
  return state.stationsListResult;
};

export const cafeList = (state) => {
  return state.cafeList;
};
export const isCafeListLoading = (state) => {
  return state.isCafeListLoading;
};
export const isCafeListError = (state) => {
  return state.isCafeListError;
};
export const cafeListErrorMessage = (state) => {
  return state.cafeListErrorMessage;
};
export const cafeListResult = (state) => {
  return state.cafeListResult;
};

export const menuList = (state) => {
  return state.menuList;
};
export const isMenuListLoading = (state) => {
  return state.isMenuListLoading;
};
export const isMenuListError = (state) => {
  return state.isMenuListError;
};
export const menuListErrorMessage = (state) => {
  return state.menuListErrorMessage;
};
export const menuListResult = (state) => {
  return state.menuListResult;
};
export const cafeInfo = (state) => {
  return state.cafeInfo;
};

export const categoriesList = (state) => {
  return state.categoriesList;
};
export const isGetCategoriesLoading = (state) => {
  return state.isGetCategoriesLoading;
};
export const isGetCategoriesError = (state) => {
  return state.isGetCategoriesError;
};
export const getCategoriesErrorMessage = (state) => {
  return state.getCategoriesErrorMessage;
};
export const getCategoriesResult = (state) => {
  return state.getCategoriesResult;
};

export const createItem = (state) => {
  return state.createItem;
};
export const isCreateItemLoading = (state) => {
  return state.isCreateItemLoading;
};
export const isCreateItemError = (state) => {
  return state.isCreateItemError;
};
export const createItemErrorMessage = (state) => {
  return state.createItemErrorMessage;
};
export const createItemResult = (state) => {
  return state.createItemResult;
};

export const editItem = (state) => {
  return state.editItem;
};
export const isEditItemLoading = (state) => {
  return state.isEditItemLoading;
};
export const isEditItemError = (state) => {
  return state.isEditItemError;
};
export const editItemErrorMessage = (state) => {
  return state.editItemErrorMessage;
};
export const editItemResult = (state) => {
  return state.editItemResult;
};

export const createCafe = (state) => {
  return state.createCafe;
};
export const isCreateCafeLoading = (state) => {
  return state.isCreateCafeLoading;
};
export const isCreateCafeError = (state) => {
  return state.isCreateCafeError;
};
export const createCafeErrorMessage = (state) => {
  return state.createCafeErrorMessage;
};
export const createCafeResult = (state) => {
  return state.createCafeResult;
};

export const editCafe = (state) => {
  return state.editCafe;
};
export const isEditCafeLoading = (state) => {
  return state.isEditCafeLoading;
};
export const isEditCafeError = (state) => {
  return state.isEditCafeError;
};
export const editCafeErrorMessage = (state) => {
  return state.editCafeErrorMessage;
};
export const editCafeResult = (state) => {
  return state.editCafeResult;
};
export const tagList = (state) => {
  return state.tagList;
};
