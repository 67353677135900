export default {
  //stations
  stationsList: [],
  isStationsLoading: false,
  isStationsError: false,
  stationsErrorMessage: '',
  isAddStationLoading: false,
  isAddStationError: false,
  isAddStaionResult: false,
  addStationMessage: '',

  isDirectionListLoading: false,
  isDirectionListError: false,
  directionListResult: false,
  directionListErrorMessage: false,
  directionList: [],
  isLoading: false,
  isError: false,
  errorMessage: '',
  result: false,
  resultData: null
};
