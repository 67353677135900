const Module = () => import('./Module.vue');
const Users = () => import('./views/Users.vue');

const moduleRoute = {
  path: '/users',
  name: 'users',
  component: Module,
  redirect: { name: 'child' },
  children: [
    {
      name: 'child',
      path: '/',
      component: Users
    }
  ]
};

export default (router) => {
  router.addRoute(moduleRoute);
};
