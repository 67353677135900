export const SET_CURRENT_USER = (state, response) => {
  state.authResult = response.success;
  state.token = response.token;
  state.user = response.user;
  state.userPerm = Object.keys(state.user.permissions).map((key) => state.user.permissions[key]);
};

export const LOGOUT_USER = (state) => {
  state.authResult = '';
  state.token = '';
  state.user = {};
};
