import StationService from '@/services/StationService';
import Helper from '@/store/helper';

export const addStation = ({ commit, dispatch }, station) => {
  commit('CREATE_STATION_START');
  return StationService.addStation(station)
    .then(({ data }) => {
      commit('CREATE_STATION_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_STATION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const editStation = ({ commit, dispatch }, station) => {
  commit('EDIT_STATION_START');
  return StationService.editStation(station)
    .then(({ data }) => {
      commit('EDIT_STATION_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_STATION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const fetchStationsList = ({ commit, dispatch }) => {
  commit('FETCH_STATION_LIST');
  return StationService.getStationsList()
    .then(({ data }) => {
      commit('SET_STATIONS', data);
    })
    .catch((err) => {
      commit('CREATE_STATIONS_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка STATIONS: `);
      throw err;
    });
};
export const getPermissionsStations = ({ commit, dispatch }) => {
  commit('FETCH_STATION_LIST');
  return StationService.getPermissionsStations()
    .then(({ data }) => {
      commit('SET_STATIONS', data);
    })
    .catch((err) => {
      commit('CREATE_STATIONS_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка STATIONS: `);
      throw err;
    });
};
export const fetchDirectionList = ({ commit, dispatch }) => {
  commit('DIRECTION_LIST_START');
  return StationService.getDirectionsList()
    .then(({ data }) => {
      commit('DIRECTION_LIST_RESULT', data);
    })
    .catch((err) => {
      commit('DIRECTION_LIST_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка DIRECTION: `);
      throw err;
    });
};
