import api from './api';

export default {
  fetchCouriersByStation(stationToken) {
    return api.get('/permissions/couriers/' + stationToken);
  },
  fetchOrdersForCourier() {
    return api.get('/permissions/orders/courier');
  }
};
