import QrService from '@/services/QrService';
import Helper from '@/store/helper';

export const fetchQr = ({ commit, dispatch }, qr) => {
  commit('FETCH_QR_START');
  return QrService.getQr(qr)
    .then(({ data }) => {
      commit('FETCH_QR_RESULT', data);
    })
    .catch((err) => {
      commit('FETCH_QR_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении QR:`);
      throw err;
    });
};

export const fetchQrList = ({ commit, dispatch }, qr) => {
  commit('FETCH_QR_LIST_START');
  return QrService.getQrList(qr)
    .then(({ data }) => {
      commit('FETCH_QR_LIST_RESULT', data);
    })
    .catch((err) => {
      commit('FETCH_QR_LIST_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении QR:`);
      throw err;
    });
};

export const createQr = ({ commit, dispatch }, qr) => {
  commit('CREATE_QR_START');
  return QrService.createQr(qr)
    .then(({ data }) => {
      commit('CREATE_QR_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_QR_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении QR:`);
      throw err;
    });
};

export const deleteQr = ({ commit, dispatch }, qr) => {
  commit('DELETE_QR_START');
  return QrService.deleteQr(qr)
    .then(({ data }) => {
      commit('DELETE_QR_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_QR_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении QR:`);
      throw err;
    });
};
