<template>
  <v-app>
    <router-view :key="$route.fullPath"></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App'
};
</script>
<style>
.v-data-table-header th {
  white-space: nowrap;
}
.v-text-field__details {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
.v-dialog--active,
.v-dialog {
  /* overflow-y: hidden; */
  overflow-x: hidden;
}
.theme--light.v-data-table {
  background-color: transparent;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none;
  color: rgba(0, 0, 0, 0.3);
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  background-color: white;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.v-data-table__selected {
  background-color: #6938ef33;
}

.v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0px 12px;
  padding: 0 12px 12px 12px;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  font-weight: 500;
  height: 18px;
  padding: 0 24px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  border-radius: 10px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 90px;
  padding: 0 16px;
}

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
  height: 60px;
  padding: 0 24px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr.v-data-table__empty-wrapper > td {
  border-radius: 10px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__empty-wrapper) > td:first-child {
  border-radius: 10px 0 0 10px;
  font-weight: bold;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__empty-wrapper) > td:last-child {
  border-radius: 0 10px 10px 0;
}
.v-text-field__details {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

@font-face {
  font-family: 'RussianRail';
  font-style: normal;
  src: url('~@/assets/fonts/fsRail/FSRAIL55.otf') format('truetype');
}
@font-face {
  font-family: 'FsRail';
  font-style: normal;
  src: url('~@/assets/fonts/russianRail/RussianRail_G_Regular.otf') format('truetype');
}
@font-face {
  font-family: 'FsRailBold';
  font-style: normal;
  src: url('~@/assets/fonts/fsRail/FSRAIL75.otf') format('truetype');
}
</style>
