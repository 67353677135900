import api from './api';

export default {
  getQr(qr) {
    return api.get('/links/qr/' + qr);
  },

  getQrLists() {
    return api.get('/links/qr');
  },
  createQr(data) {
    return api.post('links/qr/create', data);
  },
  deleteQr(token) {
    return api.post('links/qr/delete', token);
  },
  getQrList() {
    return api.get('/permissions/qrs');
  }
};
