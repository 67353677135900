export const add = ({ commit }, notification) => {
  commit('PUSH_NOTIFICATION', notification);
};

export const remove = ({ commit }, notification) => {
  commit('REMOVE_NOTIFICATION', notification);
};

export const showSnack = ({ commit }, payload) => {
  commit('SHOW_MESSAGE', payload);
};
