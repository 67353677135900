export const GET_CAFE_START = (state) => {
  state.isCafeListLoading = true;
  state.isCafeListError = false;
  state.cafeListErrorMessage = '';
  state.cafeListResult = false;
};
export const GET_CAFE_RESULT = (state, response) => {
  state.isCafeListLoading = false;
  state.isCafeListError = false;
  state.cafeListResult = response.success;
  state.cafeList = response.cafes;
};
export const GET_CAFE_ERROR = (state, error) => {
  state.isCafeListLoading = false;
  state.isCafeListError = true;
  state.cafeListErrorMessage = error;
};

export const GET_CURRENT_CAFE_START = (state) => {
  state.isCurrentCafeLoading = true;
  state.isCurrentCafeError = false;
  state.currentCafeErrorMessage = '';
  state.currentCafeResult = false;
};
export const GET_CURRENT_CAFE_RESULT = (state, response) => {
  state.isCurrentCafeLoading = false;
  state.isCurrentCafeError = false;
  state.currentCafeResult = response.success;
  state.currentCafe = response.cafe;
};
export const GET_CURRENT_CAFE_ERROR = (state, error) => {
  state.isCurrentCafeLoading = false;
  state.isCurrentCafeError = true;
  state.currentCafeErrorMessage = error;
};

export const ADD_CAFE_STATION_START = (state) => {
  state.loading = true;
  state.error = false;
  state.errorMessage = '';
  state.result = false;
};
export const ADD_CAFE_STATION_RESULT = (state, response) => {
  state.loading = false;
  state.error = false;
  state.result = response.success;
};
export const ADD_CAFE_STATION_ERROR = (state, error) => {
  state.loading = false;
  state.error = true;
  state.result = false;
  state.errorMessage = error;
};

export const DELETE_CAFE_STATION_START = (state) => {
  state.loading = true;
  state.error = false;
  state.errorMessage = '';
  state.result = false;
};
export const DELETE_CAFE_STATION_RESULT = (state, response) => {
  state.loading = false;
  state.error = false;
  state.result = response.success;
};
export const DELETE_CAFE_STATION_ERROR = (state, error) => {
  state.loading = false;
  state.error = true;
  state.result = false;
  state.errorMessage = error;
};
// export const CREATE_CAFE_START = (state) => {
//   state.isCafeCreateLoading = true;
//   state.isCafeCreateError = false;
//   state.cafeCreateErrorMessage = '';
// };
// export const CREATE_CAFE_RESULT = (state, cafe) => {
//   state.isCafeCreateLoading = false;
//   state.isCafeCreateError = false;
//   state.cafeCreate = cafe;
// };
// export const CREATE_CAFE_ERROR = (state, error) => {
//   state.isCafeCreateLoading = false;
//   state.isCafeCreateError = true;
//   state.cafeCreateErrorMessage = error;
// };

// export const CHANGE_CAFE_START = (state) => {
//   state.isCafeChangeLoading = true;
//   state.isCafeChangeError = false;
//   state.cafeChangeErrorMessage = '';
// };
// export const CHANGE_CAFE_STATUS = (state, cafe) => {
//   state.isCafeChangeLoading = false;
//   state.isCafeChangeError = false;
//   state.cafeChange = cafe;
// };
// export const CHANGE_CAFE_ERROR = (state, error) => {
//   state.isCafeChangeLoading = false;
//   state.isCafeChangeError = true;
//   state.cafeChangeErrorMessage = error;
// };
