//DEV, TEST, BATTLE
const UPLOAD_MODE = ''; // EMPTY FOR PROD, B4 DOCKER CHECK

const DEV_IP = 'http://127.0.0.1:8087';
const TEST_IP = 'https://bistro.tsi.ru';
const DOSS_API_GATE_TEST = 'http://127.0.0.1:22033';
const BATTLE_IP = 'https://bistro.dzvr.ru';

let pictureBaseUrl = '../';
let baseURL = '/api/v1';
let anotherAPI = false;
let returnUrl = 'https://bistro.dzvr.ru';

if (UPLOAD_MODE === 'DEV') {
  pictureBaseUrl = DEV_IP + '/';
  baseURL = DEV_IP + baseURL;
  anotherAPI = true;
  returnUrl = 'http://127.0.0.1:8080';
} else if (UPLOAD_MODE === 'TEST') {
  pictureBaseUrl = TEST_IP + '/';
  baseURL = TEST_IP + baseURL;
  anotherAPI = true;
  returnUrl = TEST_IP;
} else if (UPLOAD_MODE === 'DOSS_API_GATE_TEST') {
  pictureBaseUrl = DOSS_API_GATE_TEST + '/';
  baseURL = DOSS_API_GATE_TEST + baseURL;
  anotherAPI = true;
  returnUrl = DOSS_API_GATE_TEST;
} else if (UPLOAD_MODE === 'BATTLE') {
  pictureBaseUrl = BATTLE_IP + '/';
  baseURL = BATTLE_IP + baseURL;
  anotherAPI = true;
  returnUrl = BATTLE_IP;
}

export default {
  baseURL: baseURL,
  pictureBaseUrl: pictureBaseUrl,
  anotherAPI: anotherAPI,
  returnUrl: returnUrl
};
