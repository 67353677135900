import api from './api';

export default {
  getCafeList(token) {
    return api.get(`/stations/` + token + '/cafes');
  },
  getCurrentCafe(token) {
    return api.get('/cafes/' + token);
  },
  //BUSINESS
  addCafe(cafeData) {
    return api.post('/cafes', cafeData);
  },
  editCafe(cafeData) {
    return api.put('/cafes', cafeData);
  },
  getPermissionCafeList() {
    return api.get('/permissions/cafes');
  },
  addCafeStation(stationData) {
    return api.post('/links/cafeStation/create', stationData);
  },
  deleteCafeStation(stationData) {
    return api.post('/links/cafeStation/delete', stationData);
  },
  //new
  getPermissionCafesByStation(stationToken) {
    return api.get('/permissions/cafes/' + stationToken);
  }
};
