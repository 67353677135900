import api from './api';

export default {
  // CLIENT
  getCategories(cafeToken) {
    return api.get('/categories/' + cafeToken);
  },
  // BUSINESS
  addCategory(categoryData) {
    return api.post('/categories', categoryData);
  },
  editCategory(categoryData) {
    return api.put('/categories', categoryData);
  },
  addItemCategory(itemData) {
    return api.post('/links/itemCategory/create', itemData);
  },
  deleteItemCategory(itemData) {
    return api.post('/links/itemCategory/delete', itemData);
  }
};
