import api from './api';

export default {
  getOrdersByDates(query) {
    return api.get('/permissions/orders/all', {
      params: query
    });
  },
  getOrderData(orderId) {
    return api.get('/orders/' + orderId);
  },
  getOrderActions(orderId) {
    return api.get('/orders/' + orderId + '/actions');
  }
};
