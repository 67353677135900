export const CREATE_ORDER_START = (state) => {
  state.isCreateOrderLoading = true;
  state.isCreateOrderError = false;
  state.createOrderResult = false;
  state.createOrderMessage = '';
};
export const CREATE_ORDER_RESULT = (state, data) => {
  state.isCreateOrderLoading = false;
  state.createOrderResult = data.success;
  state.createOrderMessage = data.message;
  state.paymentToken = data.order.paymentToken;
  state.orderNum = data.order.orderNum;
  state.widgetSkin = data.order.widgetSkin;
};
export const CREATE_ORDER_ERROR = (state, errorMessage) => {
  state.isCreateOrderLoading = false;
  state.isCreateOrderError = true;
  state.createOrderResult = false;
  state.createOrderMessage = errorMessage;
};

export const FETCH_ORDERS_START = (state) => {
  state.isOrdersLoading = true;
  state.isOrdersError = false;
  state.ordersResult = false;
  state.ordersErrorMessage = '';
};
export const FETCH_ORDERS_RESULT = (state, data) => {
  state.isOrdersLoading = false;
  state.ordersResult = data.success;
  state.orderListByStations = data.stations;
};
export const FETCH_ORDERS_ERROR = (state, errorMessage) => {
  state.isOrdersLoading = false;
  state.isOrdersError = true;
  state.ordersResult = false;
  state.ordersErrorMessage = errorMessage;
};

export const SET_ORDER_TIMER = (state, value) => {
  state.orderListTimer = value;
};

export const CLEAR_ORDER_TIMER = (state) => {
  state.orderListTimer = null;
};
// --------------------------move in status----------------------------------------
export const CONFIRM_START = (state) => {
  state.isConfirmLoading = true;
  state.isConfirmError = false;
  state.isConfirmComplete = false;
  state.confirmMessage = '';
  state.confirmOrderNumber = '';
  state.confirmOrderDate = '';
  state.confirmResult = false;
};
export const CONFIRM_ERROR = (state, errorMessage) => {
  state.isConfirmLoading = false;
  state.isConfirmError = true;
  state.isConfirmComplete = false;
  state.confirmMessage = errorMessage;
};
export const CONFIRM_RESULT = (state, data) => {
  state.confirmResult = data.success;
  state.confirmMessage = data.message;
  state.confirmOrderNumber = data.orderNum;
  state.confirmOrderDate = data.orderDate;
  state.isConfirmLoading = false;
  state.isConfirmComplete = true;
};

export const ACCEPT_ORDER_START = (state) => {
  state.isAcceptLoading = true;
  state.isAcceptError = false;
  state.isAcceptComplete = false;
  state.acceptResult = '';
  state.acceptMessage = '';
};
export const ACCEPT_ORDER_RESULT = (state, data) => {
  state.isAcceptLoading = false;
  state.isAcceptComplete = true;
  state.acceptResult = data.success;
  state.acceptMessage = data.message;
};
export const ACCEPT_ORDER_ERROR = (state, errorMessage) => {
  state.isAcceptLoading = false;
  state.isAcceptError = true;
  state.isAcceptComplete = false;
  state.acceptMessage = errorMessage;
};

export const READY_ORDER_START = (state) => {
  state.isReadyLoading = true;
  state.isReadyError = false;
  state.isReadyComplete = false;
  state.readyResult = '';
  state.readyMessage = '';
};
export const READY_ORDER_RESULT = (state, data) => {
  state.isReadyLoading = false;
  state.isReadyComplete = true;
  state.readyResult = data.success;
  state.readyMessage = data.message;
};
export const READY_ORDER_ERROR = (state, errorMessage) => {
  state.isReadyLoading = false;
  state.isReadyError = true;
  state.isReadyComplete = false;
  state.readyMessage = errorMessage;
};

export const SET_COURIER_ORDER_START = (state) => {
  state.isSetCourierLoading = true;
  state.isSetCourierError = false;
  state.isSetCourierComplete = false;
  state.setCourierResult = '';
  state.setCourierMessage = '';
};
export const SET_COURIER_ORDER_RESULT = (state, data) => {
  state.isSetCourierLoading = false;
  state.isSetCourierComplete = true;
  state.setCourierResult = data.success;
  // state.setCourierMessage ;
};
export const SET_COURIER_ORDER_ERROR = (state, errorMessage) => {
  state.isSetCourierLoading = false;
  state.isSetCourierError = true;
  state.isSetCourierComplete = false;
  state.setCourierMessage = errorMessage;
};

export const AWAIT_ORDER_START = (state) => {
  state.isAwaitLoading = true;
  state.isAwaitError = false;
  state.isAwaitComplete = false;
  state.awaitResult = '';
  state.awaitMessage = '';
};
export const AWAIT_ORDER_RESULT = (state, data) => {
  state.isAwaitLoading = false;
  state.isAwaitComplete = true;
  state.awaitResult = data.success;
};
export const AWAIT_ORDER_ERROR = (state, errorMessage) => {
  state.isAwaitLoading = false;
  state.isAwaitError = true;
  state.isAwaitComplete = false;
  state.awaitMessage = errorMessage;
};

export const DELIVERY_ORDER_START = (state) => {
  state.isDeliveryLoading = true;
  state.isDeliveryError = false;
  state.isDeliveryComplete = false;
  state.deliveryResult = '';
  state.deliveryMessage = '';
};
export const DELIVERY_ORDER_RESULT = (state, data) => {
  state.isDeliveryLoading = false;
  state.isDeliveryComplete = true;
  state.deliveryResult = data.success;
};
export const DELIVERY_ORDER_ERROR = (state, errorMessage) => {
  state.isDeliveryLoading = false;
  state.isDeliveryError = true;
  state.isDeliveryComplete = false;
  state.deliveryMessage = errorMessage;
};

export const COMPLETE_ORDER_START = (state) => {
  state.isCompleteLoading = true;
  state.isCompleteError = false;
  state.isCompleteComplete = false;
  state.completeResult = '';
  state.completeMessage = '';
};
export const COMPLETE_ORDER_RESULT = (state, data) => {
  state.isCompleteLoading = false;
  state.isCompleteComplete = true;
  state.completeResult = data.success;
  state.completeMessage = data.message;
};
export const COMPLETE_ORDER_ERROR = (state, errorMessage) => {
  state.isCompleteLoading = false;
  state.isCompleteError = true;
  state.isCompleteComplete = false;
  state.completeMessage = errorMessage;
};

export const CANCEL_ORDER_START = (state) => {
  state.isCancelLoading = true;
  state.isCancelError = false;
  state.isCancelComplete = false;
  state.cancelResult = '';
  state.cancelMessage = '';
};
export const CANCEL_ORDER_RESULT = (state, data) => {
  state.isCancelLoading = false;
  state.isCancelComplete = true;
  state.cancelResult = data.success;
  state.cancelMessage = data.message;
};
export const CANCEL_ORDER_ERROR = (state, errorMessage) => {
  state.isCancelLoading = false;
  state.isCancelError = true;
  state.isCancelComplete = false;
  state.cancelMessage = errorMessage;
};
