export const tagsList = (state) => {
  return state.tagsList;
};

export const isTagsListLoading = (state) => {
  return state.isTagsListLoading;
};

export const isTagsListError = (state) => {
  return state.isTagsListError;
};

export const tagsListErrorMessage = (state) => {
  return state.tagsListErrorMessage;
};

export const tagsListResult = (state) => {
  return state.tagsListResult;
};

export const createTag = (state) => {
  return state.createTag;
};

export const isCreateTagLoading = (state) => {
  return state.isCreateTagLoading;
};

export const isCreateTagError = (state) => {
  return state.isCreateTagError;
};

export const createTagErrorMessage = (state) => {
  return state.createTagErrorMessage;
};

export const createTagResult = (state) => {
  return state.createTagResult;
};

export const editTag = (state) => {
  return state.editTag;
};

export const isEditTagLoading = (state) => {
  return state.isEditTagLoading;
};

export const isEditTagError = (state) => {
  return state.isEditTagError;
};

export const editTagErrorMessage = (state) => {
  return state.editTagErrorMessage;
};

export const editTagResult = (state) => {
  return state.editTagResult;
};

export const deleteCafeTag = (state) => {
  return state.deleteCafeTag;
};

export const isDeleteCafeTagLoading = (state) => {
  return state.isDeleteCafeTagLoading;
};

export const isDeleteCafeTagError = (state) => {
  return state.isDeleteCafeTagError;
};

export const deleteCafeTagErrorMessage = (state) => {
  return state.deleteCafeTagErrorMessage;
};

export const deleteCafeTagResult = (state) => {
  return state.deleteCafeTagResult;
};
