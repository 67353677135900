import CourierService from '@/services/CourierService';
import Helper from '@/store/helper';

export const fetchOrdersForCourier = ({ commit, dispatch }) => {
  commit('FETCH_ORDERS_START');
  return CourierService.fetchOrdersForCourier()
    .then(({ data }) => {
      commit('FETCH_ORDERS_RESULT', data);
    })
    .catch((err) => {
      commit('FETCH_ORDERS_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка ORDERS: `);
      throw err;
    });
};

export const fetchCouriersByStation = ({ commit, dispatch }, stationToken) => {
  commit('FETCH_COURIERS_START');
  return CourierService.fetchCouriersByStation(stationToken)
    .then(({ data }) => {
      commit('FETCH_COURIERS_RESULT', data);
    })
    .catch((err) => {
      commit('FETCH_COURIERS_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка ORDERS: `);
      throw err;
    });
};
