export const FETCH_ORDERS_START = (state) => {
  state.isFetchOrdersLoading = true;
  state.isFetchResultError = false;
  state.fetchOrderResult = false;
  state.isFetchOrderErrorMessage = '';
};

export const FETCH_ORDERS_RESULT = (state, response) => {
  state.isFetchOrdersLoading = false;
  state.isFetchResultError = false;
  state.fetchOrderResult = true;
  state.isFetchOrderErrorMessage = '';
  state.orderList = response.orders;
};

export const FETCH_ORDERS_ERROR = (state) => {
  state.isFetchOrdersLoading = false;
  state.isFetchResultError = true;
  state.fetchOrderResult = false;
  state.isFetchOrderErrorMessage = '';
};
//data.cafes === undefined ? [] : data.cafes.orders === undefined ? [] : data.cafes.orders
export const FETCH_COURIERS_START = (state) => {
  state.isCouriersLoading = true;
  state.isCouriersError = false;
  state.courierErrorMessage = '';
  state.courierResult = false;
};
export const FETCH_COURIERS_RESULT = (state, response) => {
  state.isCouriersLoading = false;
  state.isCouriersError = false;
  state.courierResult = response.success;
  state.courierList = response.stations[0].users;
};
export const FETCH_COURIERS_ERROR = (state, error) => {
  state.isCouriersLoading = false;
  state.isCouriersError = true;
  state.courierErrorMessage = error;
};
