export const FETCH_STATION_LIST = (state) => {
  state.isStationsLoading = true;
  state.isStationsError = false;
  state.stationsErrorMessage = '';
};
export const SET_STATIONS = (state, response) => {
  state.isStationsLoading = false;
  state.isStationsError = false;
  state.stationsList = response.stations;
};
export const CREATE_STATIONS_ERROR = (state, errorMessage) => {
  state.isStationsError = true;
  state.isStationsLoading = false;
  state.stationsErrorMessage = errorMessage;
};

export const CREATE_STATION_START = (state) => {
  state.isAddStationLoading = true;
  state.isAddStationError = false;
  state.isAddStaionResult = false;
  state.addStationMessage = '';
};
export const CREATE_STATION_RESULT = (state, response) => {
  state.isAddStationLoading = false;
  state.isAddStaionResult = response.success;
  // state.addStationMessage = response.station;
};
export const CREATE_STATION_ERROR = (state, errorMessage) => {
  state.isAddStationLoading = false;
  state.isAddStationError = true;
  state.isAddStaionResult = false;
  state.addStationMessage = errorMessage;
};

export const DIRECTION_LIST_START = (state) => {
  state.isDirectionListLoading = true;
  state.isDirectionListError = false;
  state.directionListResult = false;
  state.directionListErrorMessage = '';
};
export const DIRECTION_LIST_RESULT = (state, response) => {
  state.isDirectionListLoading = false;
  state.isDirectionListError = false;
  state.directionListResult = response.success;
  state.directionList = response.directions;
};
export const DIRECTION_LIST_ERROR = (state, errorMessage) => {
  state.isDirectionListLoading = false;
  state.isDirectionListError = true;
  state.directionListResult = false;
  state.directionListErrorMessage = errorMessage;
};

export const EDIT_STATION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.errorMessage = '';
};
export const EDIT_STATION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.resultData = response.station;
};
export const EDIT_STATION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.result = false;
  state.errorMessage = errorMessage;
};
