import api from './api';

export default {
  getStationsList() {
    return api.get('/permissions/stations');
  },
  getAllCafesList() {
    return api.get('/permissions/cafes');
  },
  getCafesListByStation(token) {
    return api.get('/permissions/cafes/' + token);
  },
  getMenuList(cafeToken) {
    return api.get('/permissions/items/' + cafeToken);
  },
  addItem(item) {
    return api.post('/items', item);
  },
  editItem(item) {
    return api.put('/items', item);
  },
  // IN CAFE (DELETE)
  addCafe(cafeData) {
    return api.post('/cafes', cafeData);
  },
  editCafe(cafeData) {
    return api.put('/cafes', cafeData);
  },
  //
  getCategories(cafeToken) {
    return api.get('/permissions/categories/' + cafeToken);
  },
  //DELETE
  uploadCafePicture(formData) {
    return api.post('/images/upload/cafe/picture', formData);
  },
  uploadCategoryPicture(formData) {
    return api.post('/images/upload/category/picture', formData);
  }
};
