export default {
  tagsList: [],
  isTagsListLoading: false,
  isTagsListError: false,
  tagsListResult: false,
  tagsListErrorMessage: '',

  createTag: [],
  isCreateTagLoading: false,
  isCreateTagError: false,
  createTagResult: false,
  createTagErrorMessage: '',

  editTag: [],
  isEditTagLoading: false,
  isEditTagError: false,
  editTagResult: false,
  editTagErrorMessage: '',

  deleteCafeTag: [],
  isDeleteCafeTagLoading: false,
  isDeleteCafeTagError: false,
  deleteCafeTagResult: false,
  deleteCafeTagErrorMessage: ''
};
