export const FETCH_ORDER_ACTIONS_START = (state) => {
  state.isOrderActionsLoading = true;
  state.isOrderActionsError = false;
};
export const FETCH_ORDER_ACTIONS_ERROR = (state, errorMessage) => {
  state.isOrderActionsLoading = false;
  state.isOrderActionsError = true;
  state.orderActionsErrorMessage = errorMessage;
};
export const SET_ORDER_ACTIONS = (state, response) => {
  state.isOrderActionsLoading = false;
  state.isOrderActionsError = false;
  state.orderActions = response.actions;
  state.orderActions.forEach((action) => (action.data = JSON.parse(action.data)));

  // state.orderActions.data = JSON.stringify(state.orderActions.data);
};

export const FETCH_ORDERS_START = (state) => {
  state.isOrdersLoading = true;
  state.isOrdersError = false;
};
export const FETCH_ORDERS_ERROR = (state) => {
  state.isOrdersLoading = false;
  state.isOrdersError = true;
};
export const SET_ORDERS = (state, orders) => {
  state.orders = orders;
  state.isOrdersLoading = false;
};
export const FETCH_ORDER_START = (state) => {
  state.isOrderLoading = true;
  state.isOrderError = false;
  state.orderErrorMessage = '';
};
export const FETCH_ORDER_RESULT = (state, response) => {
  state.isOrderLoading = false;
  state.isOrderError = true;
  state.orderById = response.order;
};
export const FETCH_ORDER_ERROR = (state, error) => {
  state.isOrderLoading = false;
  state.isOrderError = true;
  state.orderErrorMessage = error;
};
