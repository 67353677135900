export const GET_TAKEOUTS_START = (state) => {
  state.isTakeoutsLoading = true;
  state.isTakeoutsError = false;
  state.takeoutsErrorMessage = '';
  state.takeoutsResult = false;
};
export const GET_TAKEOUTS_RESULT = (state, data) => {
  state.isTakeoutsLoading = false;
  state.isTakeoutsError = false;
  state.takeoutsErrorMessage = '';
  state.takeoutsResult = data.success;
  state.takeoutsData = data.takeouts;
};
export const GET_TAKEOUTS_ERROR = (state, error) => {
  state.isTakeoutsLoading = false;
  state.isTakeoutsError = true;
  state.takeoutsResult = false;
  state.takeoutsErrorMessage = error;
};

export const CREATE_TAKEOUT_START = (state) => {
  state.loading = true;
  state.isError = false;
  state.errorMessage = '';
  state.result = false;
};
export const CREATE_TAKEOUT_RESULT = (state, data) => {
  state.loading = false;
  state.isError = false;
  state.errorMessage = '';
  state.result = data.success;
  state.createTakeoutData = data.takeouts;
};
export const CREATE_TAKEOUT_ERROR = (state, error) => {
  state.loading = false;
  state.isError = true;
  state.result = false;
  state.errorMessage = error;
};

export const EDIT_TAKEOUT_START = (state) => {
  state.loading = true;
  state.isError = false;
  state.errorMessage = '';
  state.result = false;
};
export const EDIT_TAKEOUT_RESULT = (state, data) => {
  state.loading = false;
  state.isError = false;
  state.errorMessage = '';
  state.result = data.success;
  state.editTakeoutData = data.takeouts;
};
export const EDIT_TAKEOUT_ERROR = (state, error) => {
  state.loading = false;
  state.isError = true;
  state.result = false;
  state.errorMessage = error;
};

export const ADD_CAFE_TAKEOUT_START = (state) => {
  state.loading = true;
  state.error = false;
  state.errorMessage = '';
  state.result = false;
};
export const ADD_CAFE_TAKEOUT_RESULT = (state, response) => {
  state.loading = false;
  state.error = false;
  state.result = response.success;
};
export const ADD_CAFE_TAKEOUT_ERROR = (state, error) => {
  state.loading = false;
  state.error = true;
  state.result = false;
  state.errorMessage = error;
};

export const DELETE_CAFE_TAKEOUT_START = (state) => {
  state.loading = true;
  state.error = false;
  state.errorMessage = '';
  state.result = false;
};
export const DELETE_CAFE_TAKEOUT_RESULT = (state, response) => {
  state.loading = false;
  state.error = false;
  state.result = response.success;
};
export const DELETE_CAFE_TAKEOUT_ERROR = (state, error) => {
  state.loading = false;
  state.error = true;
  state.result = false;
  state.errorMessage = error;
};
