export default {
  isFetchOrdersLoading: false,
  isFetchResultError: false,
  fetchOrderResult: false,
  isFetchOrderErrorMessage: '',
  orderList: [],

  isCouriersLoading: false,
  isCouriersError: false,
  courierResult: false,
  courierErrorMessage: '',
  courierList: []
};
