import AuthService from '@/services/auth';
import Helper from '@/store/helper';

export const login = ({ commit, dispatch }, authData) => {
  return AuthService.login(authData)
    .then(({ data }) => {
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      commit('SET_CURRENT_USER', data);
    })
    .catch((err) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      Helper.dispatchErrNotification(err, dispatch, `Ошибка авторизации: `);
      throw err;
    });
};

export const logout = ({ commit }) => {
  commit('LOGOUT_USER');
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};
