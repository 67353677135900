import KitchenService from '@/services/KitchenService';
import Helper from '@/store/helper';

export const fetchOrdersForKitchen = ({ commit, dispatch }) => {
  commit('FETCH_ORDERS_START');
  return KitchenService.getOrdersForKitchen()
    .then(({ data }) => {
      commit('FETCH_ORDERS_RESULT', data);
    })
    .catch((err) => {
      commit('FETCH_ORDERS_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка ORDERS: `);
      throw err;
    });
};
