export default {
  isTakeoutsLoading: false,
  isTakeoutsError: false,
  takeoutsErrorMessage: false,
  takeoutsResult: false,
  takeoutsData: [],
  loading: false,
  result: false,
  isError: false,
  createTakeoutData: null,
  editTakeoutData: null,

  error: false,
  errorMessage: ''
};
