export const CLIENT_CHECK_START = (state) => {
  state.clientCheckLoading = true;
  state.clientCheckError = false;
  state.clientCheckComplete = false;
  state.clientCheckResult = false;
  state.clientCheckMessage = '';
};
export const CLIENT_CHECK_ERROR = (state, errorMessage) => {
  state.clientCheckLoading = false;
  state.clientCheckError = true;
  state.clientCheckComplete = false;
  state.clientCheckMessage = errorMessage;
};
export const CLIENT_CHECK_RESULT = (state, data) => {
  state.isConfirmLoading = false;
  state.clientCheckError = false;
  state.isConfirmComplete = true;
  state.clientCheckResult = data.success;
  state.clientCheckMessage = data.message;
};
