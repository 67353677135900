export default {
  addItemCategory: null,
  isAddItemCategoryLoading: false,
  isAddItemCategoryError: false,
  addItemCategoryResult: false,
  addItemCategoryErrorMessage: '',

  deleteItemCategory: null,
  isDeleteItemCategoryLoading: false,
  isDeleteItemCategoryError: false,
  deleteItemCategoryResult: false,
  deleteItemCategoryErrorMessage: '',

  categoriesList: [],
  isGetCategoriesLoading: false,
  isGetCategoriesError: false,
  getCategoriesResult: false,
  getCategoriesErrorMessage: '',

  addCategory: null,
  isAddCategoryLoading: false,
  isAddCategoryError: false,
  addCategoryResult: false,
  addCategoryErrorMessage: '',

  editCategory: null,
  isEditCategoryLoading: false,
  isEditCategoryError: false,
  editCategoryResult: false,
  editCategoryErrorMessage: ''
};
