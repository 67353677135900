export const isCreateOrderLoading = (state) => {
  return state.isCreateOrderLoading;
};
export const isCreateOrderError = (state) => {
  return state.isCreateOrderError;
};
export const createOrderResult = (state) => {
  return state.createOrderResult;
};
export const createOrderMessage = (state) => {
  return state.createOrderMessage;
};
export const orderNum = (state) => {
  return state.orderNum;
};
export const paymentToken = (state) => {
  return state.paymentToken;
};

export const isOrdersLoading = (state) => {
  return state.isOrdersLoading;
};
export const isOrdersError = (state) => {
  return state.isOrdersError;
};
export const ordersResult = (state) => {
  return state.ordersResult;
};
export const ordersErrorMessage = (state) => {
  return state.ordersErrorMessage;
};
export const orderListByStations = (state) => {
  return state.orderListByStations;
};

// move in status!
export const confirmMessage = (state) => {
  return state.confirmMessage;
};
export const confirmOrderNumber = (state) => {
  return state.confirmOrderNumber;
};
export const confirmOrderDate = (state) => {
  return state.confirmOrderDate;
};
export const confirmReferenceName = (state) => {
  return state.confirmReferenceName;
};
export const isConfirmLoading = (state) => {
  return state.isConfirmLoading;
};
export const isConfirmComplete = (state) => {
  return state.isConfirmComplete;
};
export const isConfirmError = (state) => {
  return state.isConfirmError;
};
export const confirmResult = (state) => {
  return state.confirmResult;
};

export const isAcceptLoading = (state) => {
  return state.isAcceptLoading;
};
export const isAcceptError = (state) => {
  return state.isAcceptError;
};
export const isAcceptComplete = (state) => {
  return state.isAcceptComplete;
};
export const acceptResult = (state) => {
  return state.acceptResult;
};
export const acceptMessage = (state) => {
  return state.acceptMessage;
};

export const isReadyLoading = (state) => {
  return state.isReadyLoading;
};
export const isReadyComplete = (state) => {
  return state.isReadyComplete;
};
export const isReadyError = (state) => {
  return state.isReadyError;
};
export const readyResult = (state) => {
  return state.readyResult;
};
export const readyMessage = (state) => {
  return state.readyMessage;
};

export const isSetCourierLoading = (state) => {
  return state.isSetCourierLoading;
};
export const isSetCourierComplete = (state) => {
  return state.isSetCourierComplete;
};
export const isSetCourierError = (state) => {
  return state.isSetCourierError;
};
export const setCourierResult = (state) => {
  return state.setCourierResult;
};
export const setCourierMessage = (state) => {
  return state.setCourierMessage;
};

export const isAwaitLoading = (state) => {
  return state.isAwaitLoading;
};
export const isAwaitComplete = (state) => {
  return state.isAwaitComplete;
};
export const isAwaitError = (state) => {
  return state.isAwaitError;
};
export const awaitResult = (state) => {
  return state.awaitResult;
};
export const awaitMessage = (state) => {
  return state.awaitMessage;
};

export const isDeliveryLoading = (state) => {
  return state.isDeliveryLoading;
};
export const isDeliveryComplete = (state) => {
  return state.isDeliveryComplete;
};
export const isDeliveryError = (state) => {
  return state.isDeliveryError;
};
export const deliveryResult = (state) => {
  return state.deliveryResult;
};
export const deliveryMessage = (state) => {
  return state.deliveryMessage;
};

export const isCompleteLoading = (state) => {
  return state.isCompleteLoading;
};
export const isCompleteError = (state) => {
  return state.isCompleteError;
};
export const isCompleteComplete = (state) => {
  return state.isCompleteComplete;
};
export const completeResult = (state) => {
  return state.completeResult;
};
export const completeMessage = (state) => {
  return state.completeMessage;
};

export const isCancelLoading = (state) => {
  return state.isCancelLoading;
};
export const isCancelComplete = (state) => {
  return state.isCancelComplete;
};
export const isCancelError = (state) => {
  return state.isCancelError;
};
export const cancelResult = (state) => {
  return state.cancelResult;
};
export const cancelMessage = (state) => {
  return state.cancelMessage;
};
