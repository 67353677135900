export const FETCH_LOGS_START = (state) => {
  state.isLogsLoading = true;
  state.isLogsError = false;
};
export const FETCH_LOGS_ERROR = (state) => {
  state.isLogsLoading = false;
  state.isLogsError = true;
};
export const SET_LOGS = (state, logs) => {
  state.logs = logs;
  state.isLogsLoading = false;
};
