import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import notification from './modules/notification';
import log from './modules/log';
import reference from './modules/reference';
import check from './modules/check';
import station from './modules/station';
import cafe from './modules/cafe';
import menu from './modules/menu';
import order from './modules/order';
import client from './modules/client';
import qr from './modules/qr';
import kitchen from './modules/kitchen';
import tag from './modules/tag';
import category from './modules/category';
import admin from './modules/admin';
import takeouts from './modules/takeouts';
import picture from './modules/picture';
import direction from './modules/direction';
import courier from './modules/courier';
import report from './modules/report';

// import user from '../modules/user/store/user';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    notification,
    log,
    reference,
    check,
    station,
    cafe,
    menu,
    order,
    client,
    qr,
    kitchen,
    tag,
    category,
    admin,
    takeouts,
    picture,
    direction,
    courier,
    report
    // user
  }
});
