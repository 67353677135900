export const isFetchOrdersLoading = (state) => {
  return state.isFetchOrdersLoading;
};
export const isFetchResultError = (state) => {
  return state.isFetchResultError;
};
export const fetchOrderResult = (state) => {
  return state.fetchOrderResult;
};
export const isFetchOrderErrorMessage = (state) => {
  return state.isFetchOrderErrorMessage;
};
export const orderList = (state) => {
  return state.orderList;
};

export const isCouriersLoading = (state) => {
  return state.isCouriersLoading;
};
export const isCouriersError = (state) => {
  return state.isCouriersError;
};
export const courierErrorMessage = (state) => {
  return state.courierErrorMessage;
};
export const courierResult = (state) => {
  return state.courierResult;
};
export const courierList = (state) => {
  return state.courierList;
};
