export default {
  loginClientData: null,
  isLoginClientLoading: false,
  isLoginClientError: false,
  loginClientResult: false,
  loginClientErrorMessage: '',

  currentOrderData: null,
  isCurrentOrderLoading: false,
  isCurrentOrderError: false,
  currentOrderResult: false,
  currentOrderErrorMessage: '',

  allOrdersData: null,
  isAllOrdersLoading: false,
  isAllOrdersError: false,
  allOrdersResult: false,
  allOrdersErrorMessage: '',

  clientOrderTimer: null
};
