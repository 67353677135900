import api from './api';

export default {
  getOrdersList() {
    return api.get(`/permissions/orders/station`);
  },
  createOrder(order) {
    return api.post('/orders', order);
  },
  confirmPayment(token) {
    return api.post(`/orders/confirm`, token);
  },
  takeOrder(orderId) {
    return api.post(`/orders/reservation`, orderId);
  },
  acceptOrder(token) {
    return api.post(`/orders/accept`, token);
  },
  readyOrder(token) {
    return api.post(`/orders/ready`, token);
  },
  setCourierOrder(data) {
    return api.post(`/orders/courier`, data);
  },
  deliveryOrder(token) {
    return api.post(`/orders/delivery`, token);
  },
  awaitOrder(token) {
    return api.post(`/orders/await`, token);
  },
  completeOrder(token) {
    return api.post(`/orders/complete`, token);
  },
  cancelOrder(token) {
    return api.post(`/orders/cancel`, token);
  },
  getPermissionOrderByUser() {
    return api.get('/permissions/orders');
  },
  actionByOrder(token) {
    return api.get(`/orders/${token}/actions`);
  }
};
