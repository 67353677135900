export const FETCH_REFERENCE_START = (state) => {
  state.isReferenceLoading = true;
  state.isReferenceError = false;
  state.referenceErrorMessage = '';
};
export const FETCH_REFERENCE_ERROR = (state, errorMessage) => {
  state.isReferenceLoading = false;
  state.isReferenceError = true;
  state.referenceErrorMessage = errorMessage;
};
export const SET_REFERENCE = (state, referenceList) => {
  state.referenceList = referenceList.cafes;
  state.isReferenceLoading = false;
};

export const CREATE_REFERENCE_START = (state) => {
  state.isCreateReferenceLoading = true;
  state.isCreateReferenceError = false;
  state.createReferenceResult = false;
  state.createReferenceMessage = '';
};
export const CREATE_REFERENCE_ERROR = (state, errorMessage) => {
  state.isCreateReferenceLoading = false;
  state.isCreateReferenceError = true;
  state.createReferenceResult = false;
  state.createReferenceMessage = errorMessage;
};
export const SET_CREATE_REFERENCE_RESULT = (state, data) => {
  state.createReferenceResult = data.success;
  state.createReferenceMessage = data.message;
  state.isCreateReferenceLoading = false;
  state.paymentToken = data.token;
  state.orderNumber = data.orderNumber;
};

export const PDF_START = (state) => {
  state.isPdfLoading = true;
  state.isPdfError = false;
  state.pdfResult = false;
  state.pdfMessage = '';
};
export const PDF_ERROR = (state, errorMessage) => {
  state.isPdfLoading = false;
  state.isPdfError = true;
  state.pdfResult = false;
  state.pdfMessage = errorMessage;
};
export const SET_PDF_RESULT = (state, data) => {
  state.pdfData = data;
  state.isPdfLoading = false;
};
export const SEND_PDF_START = (state) => {
  state.isSendPdfLoading = true;
  state.isSendPdfError = false;
  state.sendPdfResult = false;
  state.sendPdfMessage = '';
};
export const SEND_PDF_ERROR = (state, errorMessage) => {
  state.isSendPdfLoading = false;
  state.isSendPdfError = true;
  state.sendPdfResult = false;
  state.sendPdfMessage = errorMessage;
};
export const SET_SEND_PDF_RESULT = (state, data) => {
  state.sendPdfData = data;
  state.isSendPdfLoading = false;
  state.sendPdfResult = true;
};
export const SIGN_PDF_START = (state) => {
  state.isSignPdfLoading = true;
  state.isSignPdfError = false;
  state.signPdfResult = false;
  state.signPdfMessage = '';
};
export const SIGN_PDF_ERROR = (state, errorMessage) => {
  state.isSignPdfLoading = false;
  state.isSignPdfError = true;
  state.signPdfResult = false;
  state.signPdfMessage = errorMessage;
};
export const SIGN_PDF_RESULT = (state, data) => {
  state.signPdfData = data;
  state.isSignPdfLoading = false;
  state.signPdfResult = true;
};
export const CONFIRM_START = (state) => {
  state.isConfirmLoading = true;
  state.isConfirmError = false;
  state.isConfirmComplete = false;
  state.confirmMessage = '';
  state.confirmOrderNumber = '';
  state.confirmOrderDate = '';
  state.confirmReferenceName = '';
  state.confirmResult = false;
};
export const CONFIRM_ERROR = (state, errorMessage) => {
  state.isConfirmLoading = false;
  state.isConfirmError = true;
  state.isConfirmComplete = false;
  state.confirmMessage = errorMessage;
};
export const CONFIRM_RESULT = (state, data) => {
  state.confirmResult = data.success;
  state.confirmMessage = data.message;
  state.confirmOrderNumber = data.orderNumber;
  state.confirmOrderDate = data.orderDate;
  state.confirmReferenceName = data.referenceName;
  state.isConfirmLoading = false;
  state.isConfirmComplete = true;
};
export const DELETE_PDF_DATA = (state) => {
  state.pdfData = null;
};

export const SET_PAYMENT_TOKEN = (state, token) => {
  state.paymentToken = token;
};

export const SET_ORDER_TIMER = (state, value) => {
  state.orderListTimer = value;
};

export const CLEAR_ORDER_TIMER = (state) => {
  state.orderListTimer = null;
};

export const CANCEL_ORDER_START = (state) => {
  state.isCancelLoading = true;
  state.isCancelError = false;
  state.isCancelComplete = false;
  state.cancelResult = '';
  state.cancelMessage = '';
};

export const CANCEL_ORDER_RESULT = (state, data) => {
  state.isCancelLoading = false;
  state.isCancelComplete = true;
  state.cancelResult = data.success;
  state.cancelMessage = data.message;
};

export const CANCEL_ORDER_ERROR = (state, errorMessage) => {
  state.isCancelLoading = false;
  state.isCancelError = true;
  state.isCancelComplete = false;
  state.cancelMessage = errorMessage;
};
