import LogService from '@/services/LogService';
import Helper from '@/store/helper';

export const fetchLogsForDates = ({ commit, dispatch }, { queryParams }) => {
  commit('FETCH_LOGS_START');
  return LogService.getLogsForDates(queryParams)
    .then(({ data }) => {
      commit('SET_LOGS', data.logs);
    })
    .catch((err) => {
      commit('FETCH_LOGS_ERROR');
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении логовы: `);
      throw err;
    });
};
