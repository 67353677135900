import CategoryService from '@/services/CategoryService';
import Helper from '@/store/helper';

export const addItemCategory = ({ commit, dispatch }, itemData) => {
  commit('ADD_ITEM_CATEGORY_START');
  return CategoryService.addItemCategory(itemData)
    .then(({ data }) => {
      commit('ADD_ITEM_CATEGORY_RESULT', data);
    })
    .catch((err) => {
      commit('ADD_ITEM_CATEGORY_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};

export const deleteItemCategory = ({ commit, dispatch }, itemData) => {
  commit('DELETE_ITEM_CATEGORY_START');
  return CategoryService.deleteItemCategory(itemData)
    .then(({ data }) => {
      commit('DELETE_ITEM_CATEGORY_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_ITEM_CATEGORY_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};

export const fetchCategories = ({ commit, dispatch }, cateToken) => {
  commit('GET_CATEGORIES_START');
  return CategoryService.getCategories(cateToken)
    .then(({ data }) => {
      commit('GET_CATEGORIES_RESULT', data);
    })
    .catch((err) => {
      commit('GET_CATEGORIES_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении category: `);
      throw err;
    });
};

export const addCategory = ({ commit, dispatch }, categoryData) => {
  commit('ADD_CATEGORY_START');
  return CategoryService.addCategory(categoryData)
    .then(({ data }) => {
      commit('ADD_CATEGORY_RESULT', data);
    })
    .catch((err) => {
      commit('ADD_CATEGORY_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении category: `);
      throw err;
    });
};

export const editCategory = ({ commit, dispatch }, categoryData) => {
  commit('EDIT_CATEGORY_START');
  return CategoryService.editCategory(categoryData)
    .then(({ data }) => {
      commit('EDIT_CATEGORY_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_CATEGORY_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении category: `);
      throw err;
    });
};
