import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
Vue.use(VueRouter);

function loadView(viewName) {
  return () => import(`@/views/${viewName}.vue`);
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: loadView('Home'),
    redirect: { name: 'foodClient' },
    children: [
      {
        path: 'logs',
        name: 'logs',
        component: loadView('Logs')
      },
      {
        meta: { permissions: [128, 32, 8] },
        path: 'operator',
        name: 'operator',
        component: loadView('ReferenceOperator')
      },
      {
        meta: { permissions: [128] },
        path: 'reports',
        name: 'report',
        component: loadView('ReportPage')
      },
      {
        meta: { permissions: [128] },
        path: 'takeouts',
        name: 'takeouts',
        component: loadView('Takeout')
      },
      {
        meta: { permissions: [128, 64, 32] },
        path: '/stations',
        name: 'stations',
        component: loadView('Station')
      },
      {
        meta: { permissions: [128, 16] },
        path: '/courier',
        name: 'courier',
        component: loadView('CourierPage')
      },
      {
        meta: { permissions: [128, 1] },
        path: '/amount',
        name: 'amount',
        component: loadView('Amounts'),
        props: true
      },
      {
        meta: { permissions: [128, 1] },
        path: '/kitchen',
        name: 'kitchen',
        component: loadView('Kitchen')
      },
      {
        meta: { permissions: [128, 32, 8] },
        path: '/qr',
        name: 'qr',
        component: loadView('Qr')
      },
      {
        path: '/control',
        name: 'control',
        component: loadView('Control'),
        props: true,
        meta: { permissions: [128, 8, 4, 2] },
        redirect: '/control/cafes',
        children: [
          {
            meta: { permissions: [128, 8, 4, 2] },
            path: '/control/cafes',
            component: loadView('Cafes'),
            props: true
          },
          {
            meta: { permissions: [128] },
            path: '/control/tags',
            component: loadView('Tags'),
            props: true
          }
        ]
      },
      {
        path: '/control/cafes/:cafeToken',
        component: loadView('ControlCafes'),
        props: true,
        meta: { permissions: [128, 8, 4, 2] },
        redirect: '/control/cafes/:cafeToken/products',
        children: [
          {
            path: '/control/cafes/:cafeToken/edit',
            component: loadView('CafeEdit'),
            meta: { permissions: [128, 8] },
            props: true
          },
          {
            path: '/control/cafes/:cafeToken/products',
            component: loadView('CafeProducts'),
            props: true,
            meta: { permissions: [128, 8, 4, 2] }
          },
          {
            path: '/control/cafes/:cafeToken/categories',
            component: loadView('CafeCategories'),
            props: true,
            meta: { permissions: [128, 8, 4, 2] }
          },
          {
            path: '/control/cafes/:cafeToken/tags',
            component: loadView('CafeTags'),
            props: true,
            meta: { permissions: [128] }
          }
        ]
      },
      {
        path: '/control/cafes/:cafeToken/products/:productToken',
        component: loadView('Product'),
        props: true,
        meta: { permissions: [128, 8, 4, 2] }
      },
      {
        path: '/control/cafes/:cafeToken/products/:categoryToken',
        component: loadView('Categories'),
        props: true,
        meta: { permissions: [128, 8, 4, 2] }
      }
    ]
  },

  {
    path: '/',
    name: 'foodClient',
    component: loadView('FoodClient')
  },
  {
    path: '/cafe/:token',
    name: 'cafeMenu',
    component: loadView('CafeMenu'),
    props: true
  },
  {
    path: '/client',
    name: 'client',
    component: loadView('ClientPage'),
    props: true
  },
  {
    path: '/payment',
    name: 'payment',
    component: loadView('ReferencePayment')
  },
  {
    path: '/complete',
    name: 'complete',
    component: loadView('ReferencePaymentComplete')
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('UserLogin')
  },
  {
    path: '/404',
    component: loadView('NotFound'),
    name: '404',
    props: true
  },
  {
    path: '/network-issue',
    component: loadView('NetworkIssue'),
    name: 'network-issue'
  },
  {
    path: '/oferta',
    component: loadView('Oferta'),
    name: 'oferta'
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes

  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash,
  //       behavior: 'smooth'
  //     };
  //   }
  //   return { x: 0, y: 0 }; // Go to the top of the page if no hash
  // }
});

router.beforeEach((to, from, next) => {
  let userPerm = store.getters['auth/userPerm'];
  if (to.meta.permissions !== undefined && to.meta.permissions !== null) {
    if (userPerm.find((el) => to.meta.permissions.includes(el)) !== undefined) next();
    else next({ name: 'foodClient' });
  }
  next();
});

export default router;
