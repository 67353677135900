export default {
  isCreateOrderLoading: false,
  isCreateOrderError: false,
  createOrderResult: false,
  createOrderMessage: '',
  paymentToken: '',
  orderNum: '',
  widgetSkin: '',

  isOrdersLoading: false,
  isOrdersError: false,
  ordersResult: false,
  ordersErrorMessage: '',
  orderListByStations: [],

  // move in status!
  isConfirmLoading: false,
  isConfirmError: false,
  isConfirmComplete: false,
  confirmResult: false,
  confirmMessage: '',
  confirmOrderNumber: '',
  confirmOrderDate: '',

  isAcceptLoading: false,
  isAcceptError: false,
  isAcceptComplete: false,
  acceptResult: '',
  acceptMessage: '',

  isReadyLoading: false,
  isReadyError: false,
  isReadyComplete: false,
  readyResult: '',
  readyMessage: '',

  isSetCourierLoading: false,
  isSetCourierError: false,
  isSetCourierComplete: false,
  setCourierResult: '',
  setCourierMessage: '',

  isAwaitLoading: false,
  isAwaitError: false,
  isAwaitComplete: false,
  awaitResult: '',
  awaitMessage: '',

  isDeliveryLoading: false,
  isDeliveryError: false,
  isDeliveryComplete: false,
  deliveryResult: '',
  deliveryMessage: '',

  isCompleteLoading: false,
  isCompleteError: false,
  isCompleteComplete: false,
  completeResult: '',
  completeMessage: '',

  isCancelLoading: false,
  isCancelError: false,
  isCancelComplete: false,
  cancelResult: '',
  cancelMessage: ''
};
