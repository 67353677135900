import AdminService from '@/services/AdminService';
import Helper from '@/store/helper';

export const fetchStationsList = ({ commit, dispatch }) => {
  commit('FETCH_PERMISSION_STATION_LIST');
  return AdminService.getStationsList()
    .then(({ data }) => {
      commit('SET_PERMISSION_STATIONS', data);
    })
    .catch((err) => {
      commit('CREATE_PERMISSION_STATIONS_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка STATIONS: `);
      throw err;
    });
};

export const getCafesListByStation = ({ commit, dispatch }, token) => {
  commit('GET_PERMISSION_CAFE_START');
  return AdminService.getCafesListByStation(token)
    .then(({ data }) => {
      commit('GET_PERMISSION_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('GET_PERMISSION_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении CAFE: `);
      throw err;
    });
};

export const getAllCafesList = ({ commit, dispatch }) => {
  commit('GET_ALL_CAFE_START');
  return AdminService.getAllCafesList()
    .then(({ data }) => {
      commit('GET_ALL_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('GET_ALL_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении CAFE: `);
      throw err;
    });
};

export const fetchMenuList = ({ commit, dispatch }, token) => {
  commit('GET_MENU_START');
  return AdminService.getMenuList(token)
    .then(({ data }) => {
      commit('GET_MENU_RESULT', data);
    })
    .catch((err) => {
      commit('GET_MENU_ERROR', Helper.getErrMessage(err));
      throw err;
    });
};
export const fetchCategories = ({ commit, dispatch }, cateToken) => {
  commit('GET_CATEGORIES_START');
  return AdminService.getCategories(cateToken)
    .then(({ data }) => {
      commit('GET_CATEGORIES_RESULT', data);
    })
    .catch((err) => {
      commit('GET_CATEGORIES_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении category: `);
      throw err;
    });
};

export const addNewItem = ({ commit, dispatch }, item) => {
  commit('CREATE_ITEM_START');
  return AdminService.addItem(item)
    .then(({ data }) => {
      commit('CREATE_ITEM_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_ITEM_ERROR', Helper.getErrMessage(err));
      // Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при добавлении item: `);
      throw err;
    });
};

export const editCurrItem = ({ commit, dispatch }, item) => {
  commit('EDIT_ITEM_START');
  return AdminService.editItem(item)
    .then((data) => {
      commit('EDIT_ITEM_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_ITEM_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
export const addCafe = ({ commit, dispatch }, cafeData) => {
  commit('CREATE_CAFE_START');
  return AdminService.addCafe(cafeData)
    .then(({ data }) => {
      commit('CREATE_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при добавлении CAFE: `);
      throw err;
    });
};

export const editCafe = ({ commit, dispatch }, cafeData) => {
  commit('EDIT_CAFE_START');
  return AdminService.editCafe(cafeData)
    .then((data) => {
      commit('EDIT_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
