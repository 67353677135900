export default {
  stationsList: [],
  isStationsLoading: false,
  isStationsError: false,
  stationsListResult: false,
  stationsErrorMessage: '',
  cafeInfo: {},

  cafeList: [],
  isCafeListLoading: false,
  isCafeListError: false,
  cafeListResult: false,
  cafeListErrorMessage: '',

  menuList: [],
  isMenuListLoading: false,
  isMenuListError: false,
  menuListResult: false,
  menuListErrorMessage: '',
  count: 0,

  createItem: [],
  isCreateItemLoading: false,
  isCreateItemError: false,
  createItemResult: false,
  createItemErrorMessage: '',

  editItem: [],
  isEditItemLoading: false,
  isEditItemError: false,
  editItemResult: false,
  editItemErrorMessage: '',

  categoriesList: [],
  isGetCategoriesLoading: false,
  isGetCategoriesError: false,
  getCategoriesResult: false,
  getCategoriesErrorMessage: '',

  createCafe: [],
  isCreateCafeLoading: false,
  isCreateCafeError: false,
  createCafeResult: false,
  createCafeErrorMessage: '',

  editCafe: [],
  isEditCafeLoading: false,
  isEditCafeError: false,
  editCafeResult: false,
  editCafeErrorMessage: '',

  tagList: []
};
