export const FETCH_USER_LIST = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.userResult = false;
  state.userErrorMessage = '';
};
export const FETCH_USER_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.userResult = true;
  state.userList = response.users;
};
export const FETCH_USERS_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};

export const CREATE_USER_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.userResult = false;
  state.userErrorMessage = '';
};
export const CREATE_USER_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.userResult = response.success;
  state.userData = response.user;
};
export const CREATE_USER_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};

export const EDIT_USER_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.userResult = false;
  state.userErrorMessage = '';
};
export const EDIT_USER_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.userResult = response.success;
  state.userData = response.user;
};
export const EDIT_USER_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};

export const GET_PERMISSION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const GET_PERMISSION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.permissionList = response.permissions;
};
export const GET_PERMISSION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
// ------------------ permission crud--------------------------
export const CREATE_USER_PERMISSION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const CREATE_USER_PERMISSION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.newPermission = response.permission;
  // state.userList.find((u) => u.token === response.user.token).permissions.push(response.permission);
};
export const CREATE_USER_PERMISSION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
export const EDIT_USER_PERMISSION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const EDIT_USER_PERMISSION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
};
export const EDIT_USER_PERMISSION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
export const DELETE_USER_PERMISSION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const DELETE_USER_PERMISSION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.deletedPermission = response.permission;
  // state.userList.find((u) => u.token === response.user.token).permissions.filter((p) => p.token !== response.permission.token);
};
export const DELETE_USER_PERMISSION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
//------------------------------------------------------------
// ------------------ direction crud--------------------------
export const CREATE_USER_DIRECTION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const CREATE_USER_DIRECTION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.newDirection = response.direction;
  // state.userList.find((u) => u.token === response.user.token).directions.push(response.direction);
};
export const CREATE_USER_DIRECTION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
export const EDIT_USER_DIRECTION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const EDIT_USER_DIRECTION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
};
export const EDIT_USER_DIRECTION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
export const DELETE_USER_DIRECTION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const DELETE_USER_DIRECTION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.deletedDirection = response.direction;
  // state.userList.find((u) => u.token === response.user.token).directions.filter((d) => d.token !== response.direction.token);
};
export const DELETE_USER_DIRECTION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
//------------------------------------------------------------
// ------------------ STATION crud--------------------------
export const CREATE_USER_STATION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const CREATE_USER_STATION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.newStation = response.station;
  // state.userList.find((u) => u.token === response.user.token).stations.push(response.station);
};
export const CREATE_USER_STATION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
export const EDIT_USER_STATION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const EDIT_USER_STATION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
};
export const EDIT_USER_STATION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
export const DELETE_USER_STATION_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const DELETE_USER_STATION_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.deletedStation = response.station;
  // state.userList.find((u) => u.token === response.user.token).stations.filter((s) => s.token !== response.station.token);
};
export const DELETE_USER_STATION_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
//------------------------------------------------------------
// ------------------ CAFE crud--------------------------
export const CREATE_USER_CAFE_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const CREATE_USER_CAFE_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.newCafe = response.cafe;
  // state.userList.find((u) => u.token === response.user.token).cafes.push(response.cafe);
};
export const CREATE_USER_CAFE_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
export const EDIT_USER_CAFE_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const EDIT_USER_CAFE_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
};
export const EDIT_USER_CAFE_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
export const DELETE_USER_CAFE_START = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.userErrorMessage = '';
};
export const DELETE_USER_CAFE_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.deletedCafe = response.cafe;
  // state.userList.find((u) => u.token === response.user.token).cafes.filter((c) => c.token !== response.cafe.token);
};
export const DELETE_USER_CAFE_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.userErrorMessage = errorMessage;
};
