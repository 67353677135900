export const isDirectionListLoading = (state) => {
  return state.isDirectionListLoading;
};
export const isDirectionListError = (state) => {
  return state.isDirectionListError;
};
export const directionListResult = (state) => {
  return state.directionListResult;
};
export const directionListErrorMessage = (state) => {
  return state.directionListErrorMessage;
};
export const directionList = (state) => {
  return state.directionList;
};
