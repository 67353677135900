import TagService from '@/services/TagService';
import Helper from '@/store/helper';

export const fetchTagList = ({ commit, dispatch }) => {
  commit('GET_TAG_START');
  return TagService.getCafeTags()
    .then(({ data }) => {
      commit('GET_TAG_RESULT', data);
    })
    .catch((err) => {
      commit('GET_TAG_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении TAG: `);
      throw err;
    });
};

export const addTag = ({ commit, dispatch }, tag) => {
  commit('CREATE_TAG_START');
  return TagService.addCafeTags(tag)
    .then(({ data }) => {
      commit('CREATE_TAG_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_TAG_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при добавлении TAG: `);
      throw err;
    });
};

export const editTag = ({ commit, dispatch }, tag) => {
  commit('EDIT_TAG_START');
  return TagService.editCafeTags(tag)
    .then(({ data }) => {
      commit('EDIT_TAG_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_TAG_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении TAG: `);
      throw err;
    });
};

export const addCafeTag = ({ commit, dispatch }, tag) => {
  commit('ADD_TAG_START');
  return TagService.addCafeTag(tag)
    .then(({ data }) => {
      commit('ADD_TAG_RESULT', data);
    })
    .catch((err) => {
      commit('ADD_TAG_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при добавлении TAG: `);
      throw err;
    });
};

export const deleteCafeTag = ({ commit, dispatch }, tag) => {
  commit('DELETE_TAG_START');
  return TagService.deleteCafeTag(tag)
    .then(({ data }) => {
      commit('DELETE_TAG_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_TAG_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при удалении TAG: `);
      throw err;
    });
};
