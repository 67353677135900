export const FETCH_QR_START = (state) => {
  state.isQrLoading = true;
  state.isQrError = false;
  state.qrErrorMessage = '';
  state.qrResult = false;
};
export const FETCH_QR_RESULT = (state, data) => {
  state.isQrLoading = false;
  state.isQrError = false;
  state.qrErrorMessage = '';
  state.qrResult = data.success;
  state.qrData = data.qr;
};
export const FETCH_QR_ERROR = (state) => {
  state.isQrLoading = false;
  state.isQrError = true;
  state.qrErrorMessage = '';
};

export const FETCH_QR_LIST_START = (state) => {
  state.isQrListLoading = true;
  state.isQrListError = false;
  state.qrListErrorMessage = '';
  state.qrListResult = false;
};
export const FETCH_QR_LIST_RESULT = (state, data) => {
  state.isQrListLoading = false;
  state.isQrListError = false;
  state.qrListErrorMessage = '';
  state.qrListResult = data.success;
  state.qrListData = data.qrs;
  state.dataForCreateQr = data.stations;
};
export const FETCH_QR_LIST_ERROR = (state) => {
  state.isQrListLoading = false;
  state.isQrListError = true;
  state.qrListErrorMessage = '';
};

export const CREATE_QR_START = (state) => {
  state.isCreateQrLoading = true;
  state.isCreateQrError = false;
  state.createQrErrorMessage = '';
  state.createQrResult = false;
};
export const CREATE_QR_RESULT = (state, data) => {
  state.isCreateQrLoading = false;
  state.isCreateQrError = false;
  state.createQrErrorMessage = '';
  state.createQrResult = data.success;
  state.createQrData = data.qr;
};
export const CREATE_QR_ERROR = (state, error) => {
  state.isCreateQrLoading = false;
  state.isCreateQrError = true;
  state.createQrErrorMessage = error;
};

export const DELETE_QR_START = (state) => {
  state.isDeleteQrLoading = true;
  state.isDeleteQrError = false;
  state.deleteQrErrorMessage = '';
  state.deleteQrResult = false;
};
export const DELETE_QR_RESULT = (state, data) => {
  state.isDeleteQrLoading = false;
  state.isDeleteQrError = false;
  state.deleteQrErrorMessage = '';
  state.deleteQrResult = data.success;
  state.deleteQrData = data.qr;
};
export const DELETE_QR_ERROR = (state, error) => {
  state.isDeleteQrLoading = false;
  state.isDeleteQrError = true;
  state.deleteQrErrorMessage = error;
};
