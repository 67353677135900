import TakeoutsService from '@/services/TakeoutsService';
import Helper from '@/store/helper';

export const fetchTakeoutsList = ({ commit, dispatch }) => {
  commit('GET_TAKEOUTS_START');
  return TakeoutsService.getTakeoutsList()
    .then(({ data }) => {
      commit('GET_TAKEOUTS_RESULT', data);
    })
    .catch((err) => {
      commit('GET_TAKEOUTS_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка STATIONS: `);
      throw err;
    });
};
export const fetchPermissionTakeoutsList = ({ commit, dispatch }) => {
  commit('GET_TAKEOUTS_START');
  return TakeoutsService.getPermissionTakeoutsList()
    .then(({ data }) => {
      commit('GET_TAKEOUTS_RESULT', data);
    })
    .catch((err) => {
      commit('GET_TAKEOUTS_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка STATIONS: `);
      throw err;
    });
};

export const createTakeout = ({ commit, dispatch }, takeout) => {
  commit('CREATE_TAKEOUT_START');
  return TakeoutsService.createTakeout(takeout)
    .then(({ data }) => {
      commit('CREATE_TAKEOUT_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_TAKEOUT_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка STATIONS: `);
      throw err;
    });
};

export const editTakeout = ({ commit, dispatch }, takeout) => {
  commit('EDIT_TAKEOUT_START');
  return TakeoutsService.editTakeout(takeout)
    .then(({ data }) => {
      commit('EDIT_TAKEOUT_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_TAKEOUT_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка STATIONS: `);
      throw err;
    });
};

export const addCafeTakeout = ({ commit, dispatch }, takeout) => {
  commit('ADD_CAFE_TAKEOUT_START');
  return TakeoutsService.createCafeTakeout(takeout)
    .then(({ data }) => {
      commit('ADD_CAFE_TAKEOUT_RESULT', data);
    })
    .catch((err) => {
      commit('ADD_CAFE_TAKEOUT_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};

export const deleteCafeTakeout = ({ commit, dispatch }, takeout) => {
  commit('DELETE_CAFE_TAKEOUT_START');
  return TakeoutsService.deleteCafeTakeout(takeout)
    .then(({ data }) => {
      commit('DELETE_CAFE_TAKEOUT_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_CAFE_TAKEOUT_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
