import CafeService from '@/services/CafeService';
import Helper from '@/store/helper';

// for clients
export const fetchCafeList = ({ commit, dispatch }, token) => {
  commit('GET_CAFE_START');
  return CafeService.getCafeList(token)
    .then(({ data }) => {
      commit('GET_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('GET_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении CAFE: `);
      throw err;
    });
};

export const fetchCurrentCafe = ({ commit, dispatch }, token) => {
  commit('GET_CURRENT_CAFE_START');
  return CafeService.getCurrentCafe(token)
    .then(({ data }) => {
      commit('GET_CURRENT_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('GET_CURRENT_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении CAFE: `);
      throw err;
    });
};

//for workers
export const getPermissionCafeList = ({ commit, dispatch }, token) => {
  commit('GET_CAFE_START');
  return CafeService.getPermissionCafeList(token)
    .then(({ data }) => {
      commit('GET_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('GET_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении CAFE: `);
      throw err;
    });
};
export const addCafeStation = ({ commit, dispatch }, station) => {
  commit('ADD_CAFE_STATION_START');
  return CafeService.addCafeStation(station)
    .then(({ data }) => {
      commit('ADD_CAFE_STATION_RESULT', data);
    })
    .catch((err) => {
      commit('ADD_CAFE_STATION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};

export const deleteCafeStation = ({ commit, dispatch }, station) => {
  commit('DELETE_CAFE_STATION_START');
  return CafeService.deleteCafeStation(station)
    .then(({ data }) => {
      commit('DELETE_CAFE_STATION_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_CAFE_STATION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
// export const fetchCompanyList = ({ commit, dispatch }) => {
//   commit('GET_COMPANY_LIST');
//   return CompanyService.getCompany()
//     .then(({ data }) => {
//       commit('GET_COMPANY_STATUS', data);
//     })
//     .catch((err) => {
//       commit('GET_COMPANY_ERROR', Helper.getErrMessage(err));
//       Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении COMPANY: `);
//       throw err;
//     });
// };

// export const changeCompany = ({ commit, dispatch }, company) => {
//   commit('CHANGE_COMPANY_START');
//   return CompanyService.changeCompany(company)
//     .then(({ data }) => {
//       commit('CHANGE_COMPANY_STATUS', data);
//     })
//     .catch((err) => {
//       commit('CHANGE_COMPANY_ERROR', Helper.getErrMessage(err));
//       Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении WORKER: `);
//       throw err;
//     });
// };
