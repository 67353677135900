export const isLoginClientLoading = (state) => {
  return state.isLoginClientLoading;
};
export const isLoginClientError = (state) => {
  return state.isLoginClientError;
};
export const loginClientErrorMessage = (state) => {
  return state.loginClientErrorMessage;
};
export const loginClientResult = (state) => {
  return state.loginClientResult;
};
export const loginClientData = (state) => {
  return state.loginClientData;
};

export const isCurrentOrderLoading = (state) => {
  return state.isCurrentOrderLoading;
};
export const isCurrentOrderError = (state) => {
  return state.isCurrentOrderError;
};
export const currentOrderErrorMessage = (state) => {
  return state.currentOrderErrorMessage;
};
export const currentOrderResult = (state) => {
  return state.currentOrderResult;
};
export const currentOrderData = (state) => {
  return state.currentOrderData;
};

export const isAllOrdersLoading = (state) => {
  return state.isAllOrdersLoading;
};
export const isAllOrdersError = (state) => {
  return state.isAllOrdersError;
};
export const allOrdersErrorMessage = (state) => {
  return state.allOrdersErrorMessage;
};
export const allOrdersResult = (state) => {
  return state.allOrdersResult;
};
export const allOrdersData = (state) => {
  return state.allOrdersData;
};

export const clientOrderTimer = (state) => {
  return state.clientOrderTimer;
};
