export const isQrLoading = (state) => {
  return state.isQrLoading;
};
export const isQrError = (state) => {
  return state.isQrError;
};
export const qrErrorMessage = (state) => {
  return state.qrErrorMessage;
};
export const qrResult = (state) => {
  return state.qrResult;
};
export const qrData = (state) => {
  return state.qrData;
};

export const isQrListLoading = (state) => {
  return state.isQrListLoading;
};
export const isQrListError = (state) => {
  return state.isQrListError;
};
export const qrListErrorMessage = (state) => {
  return state.qrListErrorMessage;
};
export const qrListResult = (state) => {
  return state.qrListResult;
};
export const qrListData = (state) => {
  return state.qrListData;
};
export const dataForCreateQr = (state) => {
  return state.dataForCreateQr;
};

export const isCreateQrLoading = (state) => {
  return state.isCreateQrLoading;
};
export const isCreateQrError = (state) => {
  return state.isCreateQrError;
};
export const createQrErrorMessage = (state) => {
  return state.createQrErrorMessage;
};
export const createQrResult = (state) => {
  return state.createQrResult;
};
export const createQrData = (state) => {
  return state.createQrData;
};

export const isDeleteQrLoading = (state) => {
  return state.isDeleteQrLoading;
};
export const isDeleteQrError = (state) => {
  return state.isDeleteQrError;
};
export const deleteQrErrorMessage = (state) => {
  return state.deleteQrErrorMessage;
};
export const deleteQrResult = (state) => {
  return state.deleteQrResult;
};
export const deleteQrData = (state) => {
  return state.deleteQrData;
};
