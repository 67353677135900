import api from './api';

export default {
  getStationsList() {
    //client
    return api.get(`/stations`);
  },
  //business
  //delete
  getDirectionsList() {
    return api.get('/permissions/directions');
  },
  //

  addStation(data) {
    return api.post(`/stations`, data);
  },
  editStation(data) {
    return api.put('/stations', data);
  },
  getPermissionsStations() {
    return api.get('/permissions/stations');
  }
};
