import ClientService from '@/services/ClientService';
import Helper from '@/store/helper';

export const loginClient = ({ commit, dispatch }, info) => {
  commit('LOGIN_CLIENT_START');
  return ClientService.clientLogin(info)
    .then(({ data }) => {
      commit('LOGIN_CLIENT_SET', data);
    })
    .catch((err) => {
      localStorage.removeItem('client');
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении CLIENT: `);
      throw err;
    });
};

export const fillClientData = ({ commit }) => {
  commit('FILL_CLIENT_INFO');
};

export const logoutClient = ({ commit }) => {
  commit('LOGOUT_CLIENT');
  // localStorage.removeItem('client');
};

export const getCurrentOrder = ({ commit, dispatch }, orderNum) => {
  commit('CURRENT_ORDER_START');
  return ClientService.clientOrder(orderNum)
    .then(({ data }) => {
      commit('CURRENT_ORDER_SET', data);
    })
    .catch((err) => {
      commit('CURRENT_ORDER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении ORDER: `);
      throw err;
    });
};
export const getAllOrder = ({ commit, dispatch }, orderNum) => {
  commit('ALL_ORDERS_START');
  return ClientService.clientOrders(orderNum)
    .then(({ data }) => {
      commit('ALL_ORDERS_SET', data);
    })
    .catch((err) => {
      commit('ALL_ORDERS_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при получении ORDERS: `);
      throw err;
    });
};
export const setClientOrderTimer = ({ commit, dispatch }, value) => {
  commit('SET_CLIENT_ORDER_TIMER', value);
};

export const clearClientOrderTimer = ({ commit, dispatch }) => {
  commit('CLEAR_CLIENT_ORDER_TIMER');
};
