import MenuService from '@/services/MenuService';
import Helper from '@/store/helper';

export const fetchMenuList = ({ commit, dispatch }, token) => {
  commit('GET_MENU_START');
  return MenuService.getMenuList(token)
    .then(({ data }) => {
      commit('GET_MENU_RESULT', data);
    })
    .catch((err) => {
      commit('GET_MENU_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при добавлении MENU: `);
      throw err;
    });
};

// export const addNewItem = ({ commit, dispatch }, item) => {
//   commit('CREATE_ITEM_START');
//   return MenuService.addItem(item)
//     .then(({ data }) => {
//       commit('CREATE_ITEM_RESULT', data);
//     })
//     .catch((err) => {
//       commit('CREATE_ITEM_ERROR', Helper.getErrMessage(err));
//       Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при добавлении item: `);
//       throw err;
//     });
// };

// export const editCurrItem = ({ commit, dispatch }, item) => {
//   commit('EDIT_ITEM_START');
//   return MenuService.editItem(item)
//     .then((data) => {
//       commit('EDIT_ITEM_RESULT', data);
//     })
//     .catch((err) => {
//       commit('EDIT_ITEM_ERROR', Helper.getErrMessage(err));
//       Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
//       throw err;
//     });
// };

export const getMenuItemsForAmount = ({ commit, dispatch }) => {
  commit('GET_MENU_ITEMS_FOR_AMOUNT_START');
  return MenuService.getMenuItemsForAmount()
    .then(({ data }) => {
      commit('GET_MENU_ITEMS_FOR_AMOUNT_RESULT', data);
    })
    .catch((err) => {
      commit('GET_MENU_ITEMS_FOR_AMOUNT_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};
export const putMenuItemsForAmount = ({ commit, dispatch }, obj) => {
  commit('PUT_MENU_ITEMS_FOR_AMOUNT_START');
  return MenuService.putMenuItemsForAmount(obj)
    .then(({ data }) => {
      commit('PUT_MENU_ITEMS_FOR_AMOUNT_RESULT', data);
    })
    .catch((err) => {
      commit('PUT_MENU_ITEMS_FOR_AMOUNT_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении item: `);
      throw err;
    });
};

export const getTaxGroups = ({ commit, dispatch }) => {
  commit('GET_TAX_GROUP_START');
  return MenuService.fetchTaxGroup()
    .then(({ data }) => {
      commit('GET_TAX_GROUP_RESULT', data);
    })
    .catch((err) => {
      commit('GET_TAX_GROUP_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotifications(err, dispatch, `Возникла ошибка при изменении tax: `);
      throw err;
    });
};

export const incrementCounter = ({ commit }, token) => {
  commit('INCREMENT', token);
};

export const decrementCounter = ({ commit }, token) => {
  commit('DECREMENT', token);
};

// FOR CART!
export const initializeCart = ({ commit, dispatch }) => {
  const savedCartProducts = JSON.parse(localStorage.getItem('cartProducts'));
  const savedCafeToken = localStorage.getItem('cafeToken');
  if (savedCartProducts && savedCafeToken) {
    dispatch('fetchMenuList', savedCafeToken);
    setTimeout(() => {
      commit('SET_CAFE_TOKEN', savedCafeToken);
      commit('SET_CART_PRODUCTS', savedCartProducts);
    }, 500);
  }
};

export const clearCart = ({ commit }) => {
  commit('CLEAR_CART');
  commit('SET_CAFE_TOKEN', null);
};

export const addToCart = ({ commit }, data) => {
  commit('ADD_TO_CART', data);
};

export const removeFromCart = ({ commit }, data) => {
  commit('REMOVE_FROM_CART', data);
};
export const setCafeToken = ({ commit }, cafeToken) => {
  commit('SET_CAFE_TOKEN', cafeToken);
};
