import DirectionService from '@/services/DirectionService';
import Helper from '@/store/helper';

export const fetchDirectionList = ({ commit, dispatch }) => {
  commit('DIRECTION_LIST_START');
  return DirectionService.getDirectionsList()
    .then(({ data }) => {
      commit('DIRECTION_LIST_RESULT', data);
    })
    .catch((err) => {
      commit('DIRECTION_LIST_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка DIRECTION: `);
      throw err;
    });
};
