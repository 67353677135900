export const isFetchOrdersLoading = (state) => {
  return state.isFetchOrdersLoading;
};

export const isFetchResultError = (state) => {
  return state.isFetchResultError;
};

export const fetchOrderResult = (state) => {
  return state.fetchOrderResult;
};

export const isFetchOrderErrorMessage = (state) => {
  return state.isFetchOrderErrorMessage;
};

export const fetchOrdersData = (state) => {
  return state.fetchOrdersData;
};
