export default {
  menuList: [],
  categoriesList: [],
  isMenuListLoading: false,
  isMenuListError: false,
  menuListResult: false,
  menuListErrorMessage: '',
  count: 0,

  createItem: [],
  isCreateItemLoading: false,
  isCreateItemError: false,
  createItemResult: false,
  createItemErrorMessage: '',

  editItem: [],
  isEditItemLoading: false,
  isEditItemError: false,
  editItemResult: false,
  editItemErrorMessage: '',

  menuItemsForAmount: null,
  isMenuItemsForAmountLoading: false,
  isMenuItemsForAmountError: false,
  menuItemsForAmountResult: false,
  menuItemsForAmountErrorMessage: '',

  menuItemsForPutAmount: null,
  isMenuItemsForPutAmountLoading: false,
  isMenuItemsForPutAmountError: false,
  menuItemsForPutAmountResult: false,
  menuItemsForPutAmountErrorMessage: '',

  taxGroups: null,
  isTaxGroupsLoading: false,
  isTaxGroupsError: false,
  taxGroupsResult: false,
  taxGroupsErrorMessage: '',

  //FOR CART!
  productsInCart: [],
  cafeToken: null
};
