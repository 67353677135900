import UserService from '../../services/UserService';
import Helper from '@/store/helper';

export const fetchUserList = ({ commit, dispatch }) => {
  commit('FETCH_USER_LIST');
  return UserService.getUserBySuperAdmin()
    .then(({ data }) => {
      commit('FETCH_USER_RESULT', data);
    })
    .catch((err) => {
      commit('FETCH_USERS_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка USER: `);
      throw err;
    });
};

export const addUser = ({ commit, dispatch }, user) => {
  commit('CREATE_USER_START');
  return UserService.post(user)
    .then(({ data }) => {
      commit('CREATE_USER_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_USER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const editUser = ({ commit, dispatch }, user) => {
  commit('EDIT_USER_START');
  return UserService.put(user)
    .then(({ data }) => {
      commit('EDIT_USER_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_USER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const getPermission = ({ commit, dispatch }) => {
  commit('GET_PERMISSION_START');
  return UserService.getPermission()
    .then(({ data }) => {
      commit('GET_PERMISSION_RESULT', data);
    })
    .catch((err) => {
      commit('GET_PERMISSION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};

export const getUserPermission = ({ commit, dispatch }, user) => {
  commit('GET_USER_PERMISSION_START');
  return UserService.getUserPermission(user)
    .then(({ data }) => {
      commit('GET_USER_PERMISSION_RESULT', data);
    })
    .catch((err) => {
      commit('GET_USER_PERMISSION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
// ------------------ permission crud--------------------------
export const createUserPermission = ({ commit, dispatch }, user) => {
  commit('CREATE_USER_PERMISSION_START');
  return UserService.createUserPermission(user)
    .then(({ data }) => {
      commit('CREATE_USER_PERMISSION_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_USER_PERMISSION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const updateUserPermission = ({ commit, dispatch }, user) => {
  commit('EDIT_USER_PERMISSION_START');
  return UserService.updateUserPermission(user)
    .then(({ data }) => {
      commit('EDIT_USER_PERMISSION_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_USER_PERMISSION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};

export const deleteUserPermission = ({ commit, dispatch }, user) => {
  commit('DELETE_USER_PERMISSION_START');
  return UserService.deleteUserPermission(user)
    .then(({ data }) => {
      commit('DELETE_USER_PERMISSION_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_USER_PERMISSION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
//------------------------------------------------------------
// ------------------ direction crud--------------------------
export const createUserDirection = ({ commit, dispatch }, user) => {
  commit('CREATE_USER_DIRECTION_START');
  return UserService.createUserDirection(user)
    .then(({ data }) => {
      commit('CREATE_USER_DIRECTION_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_USER_DIRECTION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const updateUserDirection = ({ commit, dispatch }, user) => {
  commit('EDIT_USER_DIRECTION_START');
  return UserService.updateUserDirection(user)
    .then(({ data }) => {
      commit('EDIT_USER_DIRECTION_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_USER_DIRECTION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const deleteUserDirection = ({ commit, dispatch }, user) => {
  commit('DELETE_USER_DIRECTION_START');
  return UserService.deleteUserDirection(user)
    .then(({ data }) => {
      commit('DELETE_USER_DIRECTION_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_USER_DIRECTION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
//------------------------------------------------------------
// ------------------ STATION crud--------------------------
export const createUserStation = ({ commit, dispatch }, user) => {
  commit('CREATE_USER_STATION_START');
  return UserService.createUserStation(user)
    .then(({ data }) => {
      commit('CREATE_USER_STATION_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_USER_STATION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const updateUserStation = ({ commit, dispatch }, user) => {
  commit('EDIT_USER_STATION_START');
  return UserService.updateUserStation(user)
    .then(({ data }) => {
      commit('EDIT_USER_STATION_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_USER_STATION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const deleteUserStation = ({ commit, dispatch }, user) => {
  commit('DELETE_USER_STATION_START');
  return UserService.deleteUserStation(user)
    .then(({ data }) => {
      commit('DELETE_USER_STATION_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_USER_STATION_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
//------------------------------------------------------------
// ------------------ CAFE crud--------------------------
export const createUserCafe = ({ commit, dispatch }, user) => {
  commit('CREATE_USER_CAFE_START');
  return UserService.createUserCafe(user)
    .then(({ data }) => {
      commit('CREATE_USER_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('CREATE_USER_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении CAFE:`);
      throw err;
    });
};
export const updateUserCafe = ({ commit, dispatch }, user) => {
  commit('EDIT_USER_CAFE_START');
  return UserService.updateUserCafe(user)
    .then(({ data }) => {
      commit('EDIT_USER_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('EDIT_USER_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const deleteUserCafe = ({ commit, dispatch }, user) => {
  commit('DELETE_USER_CAFE_START');
  return UserService.deleteUserCafe(user)
    .then(({ data }) => {
      commit('DELETE_USER_CAFE_RESULT', data);
    })
    .catch((err) => {
      commit('DELETE_USER_CAFE_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении CAFE:`);
      throw err;
    });
};
