export const takeoutsData = (state) => {
  return state.takeoutsData;
};
export const isTakeoutsLoading = (state) => {
  return state.isTakeoutsLoading;
};
export const isTakeoutsError = (state) => {
  return state.isTakeoutsError;
};
export const takeoutsErrorMessage = (state) => {
  return state.takeoutsErrorMessage;
};
export const takeoutsResult = (state) => {
  return state.takeoutsResult;
};

export const loading = (state) => {
  return state.loading;
};
export const isError = (state) => {
  return state.isError;
};
export const errorMessage = (state) => {
  return state.errorMessage;
};
export const result = (state) => {
  return state.result;
};

export const createTakeoutData = (state) => {
  return state.createTakeoutData;
};
export const editTakeoutData = (state) => {
  return state.editTakeoutData;
};

export const error = (state) => {
  return state.error;
};
